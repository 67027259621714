import 'whatwg-fetch';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import { notification } from 'antd';
import { IFetchBody, IFetchOptions, IFetchQuery } from './base.model';

const requestAPI = async <T>(
  uri: string,
  options: IFetchOptions,
): Promise<T> => {
  const apiUrl = process.env.REACT_APP_API_URL;

  let url = uri;
  if (uri.indexOf('http') !== 0) {
    url = `${apiUrl}${uri}`;
  }

  const res = await fetch(url, {
    ...options,
    headers: {
      ...(options.headers || {}),
      'x-auth-token': window.localStorage.token,
    },
  });

  if (options.mode !== 'no-cors') {
    const contentType = res.headers.get('Content-Type');
    let body = null;

    if (contentType) {
      if (contentType.includes('application/json')) {
        body = await res.json();
      } else if (contentType.includes('text/csv')) {
        body = await res.text();
      } else if (
        contentType.includes('application/pdf') ||
        contentType.includes('image/')
      ) {
        body = await res.blob();
      }
    }
    if (res.status >= 200 && res.status < 300) {
      return body;
    }
    throw body;
  } else {
    return null as any; //eslint-disable-line
  }
};

const get = async <T>(
  uri: string,
  query?: IFetchQuery,
  options?: IFetchOptions,
): Promise<T> => {
  let requestURL: string = uri;
  if (!isEmpty(query)) {
    requestURL = `${uri}?${queryString.stringify(query || {})}`;
  }

  return await requestAPI(requestURL, {
    method: 'GET',
    ...options,
  });
};

const post = async <T>(
  uri: string,
  body: IFetchBody,
  options?: IFetchOptions,
): Promise<T> => {
  let isPostForm = false;

  if (typeof body === 'object' && body.constructor === FormData) {
    isPostForm = true;
  }

  return await requestAPI(uri, {
    method: 'POST',
    body: isPostForm ? body : JSON.stringify(body),
    headers: isPostForm ? {} : { 'Content-Type': 'application/json' },
    ...options,
  });
};

const put = async <T>(
  uri: string,
  body: IFetchBody,
  options?: IFetchOptions,
): Promise<T> => {
  return post<T>(uri, body, { ...options, method: 'PUT' });
};

const del = async <T>(
  uri: string,
  body?: IFetchBody,
  options?: IFetchOptions,
): Promise<T> => {
  return post<T>(uri, body || {}, { ...options, method: 'DELETE' });
};

export const handleAPIError = (res: any) => {
  //eslint-disable-line
  notification.error({
    message: 'API error',
    description: res?.error?.message || res?.message || 'Something went wrong.',
  });
};

export const apiRequest = {
  get,
  post,
  del,
  put,
};
