import { useState } from 'react';
import flatten from 'flat';
import format from 'date-fns/format';
import pick from 'lodash/pick';
import isArray from 'lodash/isArray';

import {
  IPenalty,
  exportPenalties,
  getExportingFields,
} from '@services/penalty';
import { notification } from 'antd';
import { IFetchQuery } from '@services/base';

export function convertCsv(penalties: IPenalty[]) {
  const fields = getExportingFields();
  const items =
    penalties?.map(item => {
      const pickedFields: any = {
        ...pick(item, fields),
      };
      // Flat array
      const { tags, thread } = pickedFields;
      if (isArray(tags)) {
        pickedFields.tags = tags.join(',');
      }
      if (isArray(thread?.subscribers)) {
        pickedFields.thread.subscribers = thread.subscribers.join(',');
      }

      const flatted: any = flatten(pickedFields);

      Object.keys(flatted).forEach(key => {
        const value: any = flatted[key];
        if (
          (/_at$/.test(key) ||
            /_date$/.test(key) ||
            /_on$/.test(key) ||
            /timestamp$/.test(key)) &&
          value
        ) {
          flatted[key] = format(
            new Date(value),
            'MM/dd/yyyy HH:mm:ss',
          ) as never;
        }
      });
      return flatted;
    }) || [];

  const csvHeader: string[] = [...fields];
  let csv: any = items.map((item: any) =>
    csvHeader
      .map(fieldName => {
        const content = item[fieldName];
        return JSON.stringify(content || '');
      })
      .join(','),
  );
  csv.unshift(csvHeader.join(','));
  csv = csv.join('\r\n');

  const blob = new Blob([csv], {
    type: 'text/plain;charset=utf-8',
  });
  return blob;
}

export const useExportPenalty = (): {
  isExporting: boolean;
  exportToCSV: (query: IFetchQuery) => Promise<void>;
} => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const exportToCSV = async (query: IFetchQuery) => {
    setLoading(true);
    try {
      const res = await exportPenalties(query);
      const blob = new Blob([res], { type: 'text/csv' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = 'data.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      notification.success({
        message: `Esportazione Completata`,
      });

      setLoading(false);
    } catch (ex) {
      console.log(ex);
      notification.error({ message: 'Something went wrong' });
    } finally {
      setLoading(false);
    }
  };
  return { isExporting: isLoading, exportToCSV };
};
