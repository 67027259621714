const ImagePreview = ({
  attachmentType,
  attachmentUrl,
  downloadUrl,
}: {
  attachmentType: string;
  attachmentUrl: string;
  downloadUrl: string;
}) => {
  if (!attachmentUrl) {
    return (
      <p>
        No Preview available{' '}
        <a href={downloadUrl} target="_blank" rel="noreferrer">
          Download
        </a>
      </p>
    );
  }

  if (attachmentType === 'image')
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <a href={downloadUrl} target="_blank" rel="noreferrer">
          Download
        </a>
        <img src={downloadUrl} style={{ width: '500px' }} alt="Preview" />
      </div>
    );
  else
    return (
      <div>
        <p>
          Unsupported file type. Preview is available only for images,{' '}
          <a href={downloadUrl} target="_blank" rel="noreferrer">
            Download
          </a>
        </p>
      </div>
    );
};

export default ImagePreview;
