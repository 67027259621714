import React from 'react';
import { IntlProvider } from 'react-intl';
import ConfigProvider from 'antd/lib/config-provider';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { GlobalContextProvider } from '@contexts/GlobalContext';
import { initialValues } from '@hooks/use-auth';
import { getThemeConfig } from '@utils/theme';
interface IProps {
  children?: React.ReactNode;
}

const AppProvider = ({ children }: IProps) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  return (
    <BrowserRouter>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID || ''}>
        <GlobalContextProvider
          store={{
            ...initialValues,
          }}
        >
          <QueryClientProvider client={queryClient}>
            <IntlProvider messages={{}} locale={'en'} defaultLocale={'en'}>
              <HelmetProvider>
                <ConfigProvider {...getThemeConfig()}>
                  {children}
                </ConfigProvider>
              </HelmetProvider>
            </IntlProvider>
          </QueryClientProvider>
        </GlobalContextProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  );
};

export default AppProvider;
