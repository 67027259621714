import { useState } from 'react';
import { Form, Input, Radio, Button } from 'antd';

export interface IValues {
  displayName?: string;
  email?: string;
  permissions?: string[];
}

interface IProps {
  onChange: (values: IValues) => void;
  submitValues: () => void;
}

const AddForm = (props: IProps) => {
  const { onChange, submitValues } = props;
  const [permissionValue, setPermissionValue] = useState('view');
  const permissionsOptions = [
    { label: 'View', value: 'view' },
    { label: 'Finance', value: 'finance' },
    { label: 'Edit', value: 'edit' },
    { label: 'Superuser', value: 'superuser' },
  ];

  const onPermissionChange = (permission: string) => {
    setPermissionValue(permission);
    onChange({ permissions: [permission] });
  };

  return (
    <Form>
      <Form.Item
        label="Name"
        name="Name"
        rules={[{ required: true, message: 'Please enter the complete name' }]}
      >
        <Input
          onChange={event => {
            onChange({ displayName: event.target?.value || '' });
          }}
        />
      </Form.Item>
      <Form.Item
        label="Email"
        name="Email"
        rules={[{ required: true, message: 'Please enter the email' }]}
      >
        <Input
          onChange={event => {
            onChange({ email: event.target?.value || '' });
          }}
        />
      </Form.Item>

      <Form.Item label="Permission" name="Permission">
        <Radio.Group
          options={permissionsOptions}
          onChange={event => {
            onPermissionChange(event.target?.value);
          }}
          value={permissionValue}
          optionType="button"
          buttonStyle="solid"
        />
      </Form.Item>
      <Button onClick={submitValues} type="primary">
        Confirm
      </Button>
    </Form>
  );
};

export default AddForm;
