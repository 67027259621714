import React from 'react';
import { Card, Col, Row, Statistic } from 'antd';

interface IProps {
  isLoading: boolean;
  data?: {
    rdp: IStat;
    rda: IStat;
    overall: IStat;
  };
}

interface IStat {
  _id: string | null;
  count: number;
  total_amount: number;
}

const Stats = (props: IProps) => {
  const { data, isLoading } = props;
  const statsData = data || {
    rdp: { _id: 'RDP', count: 0, total_amount: 0 },
    rda: { _id: 'RDA', count: 0, total_amount: 0 },
    overall: { _id: null, count: 0, total_amount: 0 },
  };

  const StatsCards = Object.entries(statsData).map(([key, value]) => {
    const cardTitle = `${key.toUpperCase()} (${value.count})`;
    return (
      <Col span={4} key={key}>
        <Card size="small" style={{ borderRadius: '10px' }}>
          <Statistic
            title={cardTitle}
            loading={isLoading}
            value={value.total_amount}
            suffix={'€'}
          />
        </Card>
      </Col>
    );
  });

  return (
    <Row gutter={24} className={'mb-1'}>
      {StatsCards}
    </Row>
  );
};

export default Stats;
