import React, { useRef } from 'react';
import { useQuery } from 'react-query';
import { Skeleton } from 'antd';

import { getPenalties } from '@services/penalty';

import styles from './index.module.scss';

interface IProps {
  threadId?: string;
  ids: string[];
  userEmail?: string;
}

const Widget = (props: IProps): JSX.Element | null => {
  const { ids } = props;
  const iFrameRef = useRef<HTMLIFrameElement>(null);

  const sendMessage = function () {
    if (!iFrameRef.current) return;
    iFrameRef?.current?.contentWindow?.postMessage(
      {
        userEmail: props.userEmail,
        threadId: props.threadId,
        token: window.localStorage.token,
      },
      'https://sennder-comments-5a02e.web.app/',
    );
  };

  const { isFetching } = useQuery(
    ['update-penalty', ids.join('-')],
    () => {
      if (ids?.length) {
        return getPenalties({ ids: ids, page: 1, page_size: ids?.length });
      }
    },
    {
      keepPreviousData: false,
    },
  );

  if (isFetching) {
    return (
      <>
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  return (
    <div className={styles.iframeWrapper}>
      <iframe
        onLoad={() => sendMessage()}
        ref={iFrameRef}
        className={styles.iframe}
        src="https://sennder-comments-5a02e.web.app/"
        title="Comment"
      ></iframe>
    </div>
  );
};

export default Widget;
