import AnalyticsBarChart from '@modules/Analytics/AnalyticsBarChart';
import { handleAPIError } from '@services/base';
import { IStats, getStats } from '@services/penalty';
import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useState } from 'react';
import { useQuery } from 'react-query';

const PenaltiesAnalytics = () => {
  const [stats, setStats] = useState<{ [key: string]: IStats }>();
  const { isFetching } = useQuery(['penalties-stats'], () => getStats(), {
    keepPreviousData: false,
    onSuccess: async res => {
      setStats(res);
    },
    onError: handleAPIError,
  });

  return (
    <Layout style={{ width: '100%', height: '100%' }}>
      <Content
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '100px',
        }}
      >
        <AnalyticsBarChart isLoading={isFetching} data={stats?.status} />

        <AnalyticsBarChart isLoading={isFetching} data={stats?.carriers} />
        <AnalyticsBarChart isLoading={isFetching} data={stats?.line} />
      </Content>
    </Layout>
  );
};

export default PenaltiesAnalytics;
