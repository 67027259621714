import React from 'react';
import { Typography } from 'antd';

import { IPenalty } from '@services/penalty';

const { Text } = Typography;

const CellPlate = ({ data }: { data: IPenalty }): JSX.Element | null => {
  const { trailer_plate, truck_plate } = data;
  return (
    <>
      <Text strong>{truck_plate || null}</Text>
      <br />
      <Text type={'secondary'}>
        {truck_plate ? trailer_plate || '-' : null}
      </Text>
    </>
  );
};

export default CellPlate;
