import useStore from './use-store';

import { IAuthInfo, refreshToken, verifyGoogleToken } from '@services/auth';
import { handleAPIError } from '@services/base';

interface IAuth {
  isAuthenticating: boolean;
  data: IAuthInfo | null;
  login: (token: string) => Promise<void>;
  logout: () => Promise<void>;
  checkLogin: () => Promise<void>;
}

const flatPermissions = (permissions: string[]) => {
  const results: { [key: string]: boolean } = {};
  permissions.forEach(key => {
    results[key] = true;
  });

  return results;
};

const useAuth = (): IAuth => {
  const [data, setData] = useStore<IAuthInfo | null>('authInfo');
  const [isAuthenticating, setAuthenticating] =
    useStore<boolean>('isAuthenticating');

  const login = async (googleToken: string) => {
    try {
      const res = await verifyGoogleToken(googleToken);
      const { token, user } = res;
      if (token && user) {
        window.localStorage?.setItem('token', token);
        setData({
          ...user,
          flatPermissions: flatPermissions(user.permissions || []),
        });
      } else {
        handleAPIError(res);
      }
    } catch (ex) {
      handleAPIError(ex);
    }
  };

  const checkLogin = async () => {
    const oldToken = window.localStorage?.getItem('token');
    if (oldToken) {
      try {
        const res = await refreshToken();
        const { token, user } = res;
        if (token && user) {
          setData({
            ...user,
            flatPermissions: flatPermissions(user.permissions || []),
          });
          window.localStorage?.setItem('token', token);
        } else {
          handleAPIError(res);
        }
      } catch (ex) {
        window.localStorage?.removeItem('token');
        handleAPIError(ex);
      } finally {
        setAuthenticating(false);
      }
    } else {
      setAuthenticating(false);
    }
  };

  const logout = async () => {
    window.localStorage?.removeItem('token');
    window.location.href = '/';
  };

  return {
    data,
    login,
    logout,
    checkLogin,
    isAuthenticating,
  };
};

export const initialValues = {
  isAuthenticating: true,
};

export default useAuth;
