import React from 'react';
import { Form, Select } from 'antd';
export interface IValues {
  category?: string;
  reason?: string;
}

interface IProps {
  onChange: (values: IValues) => void;
}

const CATEGORIES = ['Justification accepted', 'Goodwill'];
const REASONS = [
  'Mezzo vuoto',
  'Guasto Mezzo',
  'Tempi di percorrenza non rispettabili',
  'Ritardo causato da Hub/Filiale/Cliente',
  'Traffico, cantieri stradali, condizioni metereologiche',
  'Ritardo traghetto',
  'Incidente stradale al mezzo che esegue il servizio',
  'Fermo Polizia',
  'Orari aperture Hub/Filiali non compatibili con schedulati',
  'Altro',
];
const ReasonForm = (props: IProps) => {
  const { onChange } = props;
  return (
    <Form
      name="penalty"
      initialValues={{}}
      layout="vertical"
      autoComplete="off"
    >
      <Form.Item
        label="Category"
        name="category"
        rules={[{ required: true, message: 'Please select the category' }]}
      >
        <Select
          onChange={value => {
            onChange({ category: value });
          }}
        >
          {CATEGORIES.map(category => {
            return (
              <Select.Option key={category} value={category}>
                {category}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Reason"
        name="reason"
        rules={[{ required: true, message: 'Please enter the reason' }]}
      >
        <Select
          onChange={value => {
            onChange({ reason: value });
          }}
        >
          {REASONS.map(reason => {
            return (
              <Select.Option key={reason} value={reason}>
                {reason}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </Form>
  );
};

export default ReasonForm;
