import React, { useState } from 'react';
import { Modal, Select } from 'antd';
import { ImportOutlined } from '@ant-design/icons';

import useModal, { IModalPayload } from '@hooks/use-modal';

import NormalImportContext from './context/NormalImportContext';
import NoShowImportContext from './context/NoShowImportContext';

const ImportNoShowModal = () => {
  const [modal, setModal] = useModal<IModalPayload<any>>('import-no-show');
  const [penaltiesType, setPenaltiesType] = useState<string>('Normal');
  const onClose = () => {
    setModal({ isOpen: false, payload: null });
  };

  return (
    <Modal
      destroyOnClose
      centered
      width={720}
      onCancel={onClose}
      footer={null}
      title={
        <>
          <ImportOutlined className={'mr-2'} />
          Import {penaltiesType} CSV
        </>
      }
      visible={modal?.isOpen || false}
    >
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 10,
          }}
        >
          <p style={{ marginRight: '10px', marginBottom: '0' }}>
            Penalties Type
          </p>
          <Select
            options={[
              { value: 'Normal', label: <span>Normal</span> },
              { value: 'No Show', label: <span>No Show</span> },
            ]}
            value={penaltiesType}
            onChange={value => setPenaltiesType(value)}
            style={{ marginTop: '0' }}
          />
        </div>
        {penaltiesType === 'Normal' ? (
          <NormalImportContext onClose={onClose} />
        ) : (
          <NoShowImportContext onClose={onClose} />
        )}
      </>
    </Modal>
  );
};

export default ImportNoShowModal;
