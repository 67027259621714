import { useState } from 'react';
import useModal, { IModalPayload } from '@hooks/use-modal';
import { Modal, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Form, { IValues } from './components/Form';
import { IUserCreate, createUser } from '@services/user';

const AddUserModal = () => {
  const [modal, setModal] =
    useModal<IModalPayload<{ ids: string[]; type: string }>>('add-user');

  const [user, setUser] = useState<IValues>({});
  const onClose = () => {
    setUser({});
    setModal({ isOpen: false, payload: null });
  };
  const submitValues = () => {
    if (
      !user.displayName ||
      !user.email ||
      !user.permissions ||
      user.permissions.length === 0
    )
      return notification.error({
        message: 'Invalid Payload',
        description: 'Please fill all the values correctly',
      });

    createUser(user as IUserCreate)
      .then(() => {
        onClose();
        notification.success({
          message: 'Created',
          description: 'User created successfully',
        });
      })
      .catch(err => {
        notification.error({
          message: 'Error',
          description: 'Cannot create user',
        });
      });
  };

  const onChange = (data: IValues) => {
    setUser({
      ...user,
      ...data,
    });
  };

  return (
    <Modal
      destroyOnClose
      centered
      width={640}
      onCancel={onClose}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      footer={null}
      title={
        <>
          <PlusOutlined className={'mr-2'} />
          Add User
        </>
      }
      visible={modal?.isOpen || false}
    >
      <Form onChange={onChange} submitValues={submitValues} />
    </Modal>
  );
};

export default AddUserModal;
