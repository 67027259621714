import React, { useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';

import useAuth from '@hooks/use-auth';
import { notification, Spin } from 'antd';

import styles from './index.module.scss';

const LoginWithGooglePage: React.FC = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const { login } = useAuth();
  return (
    <div className={styles.container}>
      <Spin spinning={isLoading}>
        <GoogleLogin
          size={'large'}
          onSuccess={async res => {
            setLoading(true);
            await login(res.credential || '');
            setLoading(false);
          }}
          onError={() => {
            setLoading(false);
            notification.error({ message: 'Authentication failed' });
          }}
        />
      </Spin>
    </div>
  );
};

export default LoginWithGooglePage;
