import React from 'react';
import { Form, Input } from 'antd';

const { TextArea } = Input;

export interface IValues {
  content?: string;
}

interface IProps {
  onChange: (values: IValues) => void;
}

const ReasonForm = (props: IProps) => {
  const { onChange } = props;
  return (
    <Form
      name="penalty"
      initialValues={{}}
      layout="vertical"
      autoComplete="off"
    >
      <Form.Item
        label="Integration request body"
        name="content"
        rules={[{ required: true, message: 'Please select content' }]}
      >
        <TextArea
          autoSize={{ minRows: 2 }}
          onChange={event => {
            onChange({ content: event.target?.value || '' });
          }}
        />
      </Form.Item>
    </Form>
  );
};

export default ReasonForm;
