import { IStats } from '@services/penalty';
import { Spin } from 'antd';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

interface IProps {
  isLoading: boolean;
  data?: IStats;
}
const AnalyticsBarChart = (props: IProps) => {
  const { data, isLoading } = props;
  return (
    <div>
      <Spin spinning={isLoading} />

      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 0,
          right: 20,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="_id" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="entries_count" fill="#ff8300" />
      </BarChart>
    </div>
  );
};

export default AnalyticsBarChart;
