import React from 'react';
import { Descriptions, Typography } from 'antd';
import { FormattedNumber } from 'react-intl';

import { IPenalty } from '@services/penalty';

const { Text } = Typography;

const Financials = ({ data }: { data: IPenalty }) => {
  const amount = (data.penalty_percentage || 0) * (data?.total_net_cost || 0);

  return (
    <Descriptions size={'middle'} title="Financials" bordered column={2}>
      <Descriptions.Item label="Initial penalty percentage">
        {data.initial_penalty_percentage
          ? data.initial_penalty_percentage * 100
          : '-'}
        %
      </Descriptions.Item>
      <Descriptions.Item label="Penalty percentage">
        {data.penalty_percentage * 100 || 0}%
      </Descriptions.Item>
      <Descriptions.Item label="Amount">
        <FormattedNumber value={amount} />€
      </Descriptions.Item>
      <Descriptions.Item label="NET cost">
        <Text>
          <FormattedNumber value={data.total_net_cost || 0} />€
        </Text>
      </Descriptions.Item>
      <Descriptions.Item label="NET price">
        <Text>
          <FormattedNumber value={data.total_net_price || 0} />€
        </Text>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default Financials;
