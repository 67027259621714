import React from 'react';
import { Card, Col, Row, Statistic } from 'antd';

import { IStats } from '@services/penalty';

interface IProps {
  isLoading: boolean;
  data?: IStats;
}

const Stats = (props: IProps) => {
  const { data, isLoading } = props;
  const validStatuses = [
    'TOTAL',
    'TO_REVIEW',
    'PENDING',
    'BILLED',
    'APPLIED',
    'NOT_APPLIED',
  ];

  const validStats = data?.length
    ? data
        ?.filter(stats => validStatuses.includes(stats._id))
        .sort(
          (a, b) => validStatuses.indexOf(a._id) - validStatuses.indexOf(b._id),
        )
    : [];
  const StatsCards = validStats?.map(stats => {
    const cardTitle = `${stats._id} (${stats.entries_count})`;
    return (
      <Col span={4} key={stats._id}>
        <Card size="small" style={{ borderRadius: '10px' }}>
          <Statistic
            title={cardTitle}
            loading={isLoading}
            value={stats.total_amount}
            suffix={'€'}
          />
        </Card>
      </Col>
    );
  });
  return (
    <Row gutter={24} className={'mb-1'}>
      {StatsCards}
    </Row>
  );
};

export default Stats;
