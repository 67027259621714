import { IPenalty } from '@services/penalty';
import JustificationAttachments from './JustificationAttachments';

const CellJustificationAttachments = ({
  data,
}: {
  data: IPenalty;
}): JSX.Element | null => {
  const { justification } = data;

  if (justification) {
    const attachmentsList =
      justification.attachments_url && justification.attachments_url.length > 0
        ? justification.attachments_url
        : null;

    return (
      <>
        {attachmentsList ? (
          <JustificationAttachments penalty={data} list={attachmentsList} />
        ) : null}
      </>
    );
  }
  return null;
};

export default CellJustificationAttachments;
