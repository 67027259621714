import React from 'react';
import useModal, { IModalPayload } from '@hooks/use-modal';
import { Modal } from 'antd';
import { PullRequestOutlined } from '@ant-design/icons';

import Widget from './Widget';

const PenaltyRequestInformationModal = () => {
  const [modal, setModal] =
    useModal<IModalPayload<{ ids: string[]; type: string }>>('update-penalty');

  const { ids = [] } = modal?.payload || {};

  const onClose = () => {
    setModal({ isOpen: false, payload: null });
  };

  return (
    <Modal
      destroyOnClose
      centered
      width={640}
      onCancel={onClose}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      footer={null}
      title={
        <>
          <PullRequestOutlined className={'mr-2'} />
          Request information
        </>
      }
      visible={
        (modal?.isOpen && modal?.payload?.type === 'request-information') ||
        false
      }
    >
      <Widget ids={ids} onClose={onClose} />
    </Modal>
  );
};

export default PenaltyRequestInformationModal;
