import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Skeleton, Button, Space, Spin, Alert, notification } from 'antd';
import { PullRequestOutlined } from '@ant-design/icons';

import { handleAPIError, IFetchQuery } from '@services/base';
import {
  getPenalties,
  IPenalty,
  IPenaltyResult,
  updatePenalties,
} from '@services/penalty';
import useModal, { IModalPayload } from '@hooks/use-modal';

import DataGrid from '../PenaltyApplicationModal/components/DataGrid';
import Form, { IValues } from './components/Form';

interface IProps {
  ids: string[];
  onClose: () => void;
}

const Widget = (props: IProps): JSX.Element | null => {
  const { ids, onClose } = props;
  const [, openResultModal] =
    useModal<IModalPayload<IPenaltyResult[]>>('penalty-result');
  const [data, setData] = useState<IPenalty[]>([]);
  const [values, setValues] = useState<IValues>({});
  const queryClient = useQueryClient();

  const { isFetching } = useQuery(
    ['update-penalty', ids.join('-')],
    () => {
      if (ids?.length) {
        return getPenalties({ ids: ids, page: 1, page_size: ids?.length });
      }
    },
    {
      keepPreviousData: false,
      onSuccess: res => {
        setData(
          (res?.penalties || []).map(item => {
            const { status } = item;
            return {
              ...item,
              message:
                ['PENDING', 'TO_REVIEW'].indexOf(status || '') < 0
                  ? 'Invalid status'
                  : '',
            };
          }),
        );
      },
      onError: handleAPIError,
    },
  );
  const { isLoading: isSubmitting, mutate } = useMutation(
    (payload: IFetchQuery[]) => {
      return updatePenalties(payload);
    },
    {
      onSuccess: async res => {
        await queryClient.invalidateQueries('penalties');
        onClose();
        if (res.every(({ success }) => success)) {
          notification.success({
            message: 'Request information',
            description: 'Requested information successfully',
          });
        } else {
          openResultModal({ isOpen: true, payload: res || [] });
        }
      },
      onError: handleAPIError,
    },
  );

  const submit = () => {
    const payload: IFetchQuery[] = data
      .filter(({ message }) => !message)
      .map(item => {
        return {
          _id: item._id,
          integration_request_body: values.content,
        };
      });
    mutate(payload);
  };

  const onChange = (data: IValues) => {
    setValues({
      ...values,
      ...data,
    });
  };

  const onRemove = (id: string) => {
    const filtered = data.filter(({ _id }) => id !== _id);
    if (filtered.length) {
      setData(filtered);
    } else {
      onClose();
    }
  };

  if (isFetching) {
    return (
      <>
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  if (data) {
    const validItems = data.filter(({ message }) => !message);
    const isValid = values.content && validItems.length;

    return (
      <Spin spinning={isSubmitting}>
        {validItems.length && validItems.length < data.length ? (
          <>
            <Alert
              message="The invalid items will be ignored after clicking ‘Confirm‘"
              type="warning"
              showIcon
            />
            <br />
          </>
        ) : null}
        {validItems.length === 0 ? (
          <>
            <Alert message="No valid items" type="error" showIcon />
            <br />
          </>
        ) : null}

        <DataGrid data={data} onRemove={onRemove} />
        <br />
        {validItems.length ? <Form onChange={onChange} /> : null}
        <div className={'text-center'}>
          <Space align={'center'}>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              onClick={submit}
              disabled={!isValid}
              type="primary"
              icon={<PullRequestOutlined />}
            >
              Confirm
              {validItems.length > 1 ? (
                <span className="ml-1">({validItems.length})</span>
              ) : null}
            </Button>
          </Space>
        </div>
      </Spin>
    );
  }

  return null;
};

export default Widget;
