import React from 'react';
import useModal, { IModalPayload } from '@hooks/use-modal';
import { Modal } from 'antd';
import { TagsOutlined } from '@ant-design/icons';

import Widget from './Widget';

const PenaltyResetModal = () => {
  const [modal, setModal] =
    useModal<IModalPayload<{ ids: string[]; type: string }>>('update-penalty');
  const { ids = [] } = modal?.payload || {};

  const onClose = () => {
    setModal({ isOpen: false, payload: null });
  };

  return (
    <Modal
      destroyOnClose
      centered
      width={640}
      onCancel={onClose}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      footer={null}
      title={
        <>
          <TagsOutlined className={'mr-2'} />
          Reset Penalties
        </>
      }
      visible={
        (modal?.isOpen && modal?.payload?.type === 'reset-penalty') || false
      }
    >
      <Widget ids={ids} onClose={onClose} />
    </Modal>
  );
};

export default PenaltyResetModal;
