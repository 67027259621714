import { apiRequest } from '@services/base';

import { IAuthResponse } from './auth.model';

const apiPrefix = process.env.REACT_APP_API_PATH_PREFIX;
export const verifyGoogleToken = async (token: string) => {
  return apiRequest.post<IAuthResponse>(`${apiPrefix}/auth`, { token });
};

export const refreshToken = async () => {
  return apiRequest.get<IAuthResponse>(`${apiPrefix}/auth`);
};
