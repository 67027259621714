import { Layout } from 'antd';
import { Navigate, useLocation } from 'react-router-dom';

import React from 'react';
import Widget from './Widget';
import styles from '@components/AdminLayout/styles.module.scss';
import logo from '@assets/images/sennder-italia.png';
import useAuth from '@hooks/use-auth';
import AppMenu from '@components/AppMenu';
import AccountInvoiceModal from './components/AccountInvoiceModal';
import CompensateInvoiceModal from './components/CompensateInvoiceModal';
import DeleteInvoiceModal from './components/DeleteInvoiceModal';
import CreateInvoiceRdaModal from './components/CreateInvoiceRdaModal';

const { Header, Content } = Layout;

const FinancialPage = () => {
  let { isAuthenticating, data } = useAuth();

  let location = useLocation();

  if (isAuthenticating) {
    return null;
  }

  if (!data) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  const { email, photoURL, displayName } = data;

  return (
    <>
      <Header className={styles.header}>
        <div className={styles.avatar}>
          {photoURL ? (
            <img src={photoURL} alt={email} />
          ) : (
            displayName.charAt(0)
          )}
        </div>
        <div className={styles.logo}>
          <img src={logo} alt={'Sennder'} />
        </div>
        <AppMenu />
      </Header>
      <Content className={styles.content}>
        <Layout style={{ height: '100%' }}>
          <Widget />
          <AccountInvoiceModal />
          <CompensateInvoiceModal />
          <DeleteInvoiceModal />
          <CreateInvoiceRdaModal />
        </Layout>
      </Content>
    </>
  );
};

export default FinancialPage;
