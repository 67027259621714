import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Skeleton, Button, Space, Spin, notification, DatePicker } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import moment from 'moment';

import { handleAPIError, IFetchQuery } from '@services/base';
import {
  getPenalties,
  IPenalty,
  IPenaltyResult,
  updatePenalties,
} from '@services/penalty';
import useModal, { IModalPayload } from '@hooks/use-modal';

import DataGrid from '../PenaltyApplicationModal/components/DataGrid';

interface IProps {
  ids: string[];
  onClose: () => void;
}

const Widget = (props: IProps): JSX.Element | null => {
  const { ids, onClose } = props;
  const [, openResultModal] =
    useModal<IModalPayload<IPenaltyResult[]>>('penalty-result');
  const [data, setData] = useState<IPenalty[]>([]);
  const [expirationDate, setExpirationDate] = useState(() => {
    return moment().add(7, 'day').hour(23).minute(59).second(0);
  });

  const queryClient = useQueryClient();

  const { isFetching } = useQuery(
    ['update-penalty', ids.join('-')],
    () => {
      if (ids?.length) {
        return getPenalties({ ids: ids, page: 1, page_size: ids?.length });
      }
    },
    {
      keepPreviousData: false,
      onSuccess: data => {
        setData(data?.penalties || []);
      },
      onError: handleAPIError,
    },
  );

  const { isLoading: isSubmitting, mutate } = useMutation(
    (payload: IFetchQuery[]) => {
      return updatePenalties(payload);
    },
    {
      onSuccess: async res => {
        onClose();
        await queryClient.invalidateQueries('penalties');
        if (res.every(({ success }) => success)) {
          notification.success({
            message: 'Reset Penalties',
            description: 'Penalties reset to pending',
          });
        } else {
          openResultModal({ isOpen: true, payload: res || [] });
        }
      },
      onError: handleAPIError,
    },
  );

  const submit = () => {
    const payload: IFetchQuery[] = data
      .filter(({ message }) => !message)
      .map(item => {
        const { _id } = item;
        return {
          _id,
          status: 'PENDING',
          tags: ['resetted'],
          expiration_date: expirationDate.utc(),
        };
      });
    mutate(payload);
  };

  const onRemove = (id: string) => {
    const filtered = data.filter(({ _id }) => id !== _id);
    if (filtered.length) {
      setData(filtered);
    } else {
      onClose();
    }
  };

  if (isFetching) {
    return (
      <>
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  const handleDateChange = (date: any) => {
    setExpirationDate(date);
  };

  if (data) {
    const isValid = true;

    return (
      <Spin spinning={isSubmitting}>
        <DataGrid data={data} onRemove={onRemove} />
        <br />

        <div style={{ textAlign: 'center' }}>
          <Space
            direction="vertical"
            size="middle"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Space align="center">
              <span>Expiration Date:</span>
              <DatePicker
                showTime={{
                  format: 'HH:mm',
                }}
                onChange={handleDateChange}
                defaultValue={expirationDate}
                format="YYYY-MM-DD HH:mm"
              />
            </Space>

            <Space align="center">
              <Button onClick={onClose}>Cancel</Button>
              <Button
                onClick={submit}
                disabled={!isValid}
                type="primary"
                icon={<CheckOutlined />}
              >
                Confirm
                {data.length > 1 ? (
                  <span style={{ marginLeft: 4 }}>({data.length})</span>
                ) : null}
              </Button>
            </Space>
          </Space>
        </div>
      </Spin>
    );
  }

  return null;
};

export default Widget;
