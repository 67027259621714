import React from 'react';
import { Typography, Space, Popover } from 'antd';

import { IPenalty } from '@services/penalty';

const { Text } = Typography;

const CellArc = ({ data }: { data: IPenalty }): JSX.Element => {
  const { route_composition, route_km, tour_composition, tour_km } = data;

  const hoverContent = (
    <Space direction={'vertical'}>
      <Text>
        <span className="mr-2">Route Composition:</span>
        {data.route_composition ? <Text>{route_composition}</Text> : null}
      </Text>
      <Text>
        <span className="mr-2">Route KM:</span>
        {data.route_km && data.route_km > 0 ? <Text>{route_km}</Text> : null}
      </Text>
      <Text>
        <span className="mr-2">Tour Composition:</span>
        {data.tour_composition ? <Text>{tour_composition}</Text> : null}
      </Text>
      <Text>
        <span className="mr-2">Tour KM:</span>
        {data.tour_km && data.tour_km > 0 ? <Text>{tour_km}</Text> : null}
      </Text>
    </Space>
  );
  return (
    <>
      <Popover content={hoverContent} destroyTooltipOnHide>
        <Text>{route_composition}</Text>
        {route_km && route_km > 0 ? (
          <>
            <br />
            <Text type={'secondary'}>{route_km} Km</Text>
          </>
        ) : null}
        <br />
      </Popover>
    </>
  );
};

export default CellArc;
