import React from 'react';
import { Tag, Tooltip } from 'antd';
import { useQuery } from 'react-query';

import { getStatuses, IPenalty } from '@services/penalty';
import { FormattedDate } from 'react-intl';

const CellStatus = (props: { data: IPenalty }): JSX.Element | null => {
  const { data: res } = useQuery('penalty-status', () => getStatuses(), {});

  const {
    data: {
      status,
      processing_category,
      processing_body,
      billed_at,
      invoice_number,
    },
  } = props;

  const { data } = res || {};
  const matched = data?.find(({ code }) => status === code);

  if (status === 'BILLED') {
    return (
      <Tooltip title={'Invoice Number: ' + invoice_number?.toString()}>
        <Tag
          color={matched?.color || 'default'}
          style={{ whiteSpace: 'normal' }}
        >
          <strong>{matched?.name || status}</strong>
          {billed_at ? (
            <div>
              <FormattedDate
                value={billed_at}
                day="numeric"
                month="numeric"
                year="numeric"
                hour="numeric"
                minute="numeric"
              />
            </div>
          ) : null}
        </Tag>
      </Tooltip>
    );
  }

  if (status === 'BILLABLE') {
    return (
      <Tag color={matched?.color || 'default'} style={{ whiteSpace: 'normal' }}>
        <strong>{matched?.name || status}</strong>
        <div>Pending submission</div>
      </Tag>
    );
  }

  return (
    <Tooltip title={processing_body}>
      <Tag color={matched?.color || 'default'} style={{ whiteSpace: 'normal' }}>
        <strong>{matched?.name || status}</strong>
        {processing_category ? <div>{processing_category}</div> : null}
      </Tag>
    </Tooltip>
  );
};

export default CellStatus;
