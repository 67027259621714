import React, { useEffect } from 'react';
import Layout from 'antd/lib/layout';
import Spin from 'antd/lib/spin';

import useAuth from '@hooks/use-auth';

import styles from './styles.module.scss';

interface IProps {
  children?: React.ReactNode;
}

const AppLayout = ({ children }: IProps) => {
  const { checkLogin, isAuthenticating } = useAuth();

  useEffect(() => {
    (async () => {
      await checkLogin();
    })();
  }, []); //eslint-disable-line

  if (isAuthenticating) {
    return (
      <div className={styles.loadingContainer}>
        <Spin spinning size="large" delay={200}></Spin>
      </div>
    );
  }

  return <Layout className={styles.layout}>{children}</Layout>;
};

export default AppLayout;
