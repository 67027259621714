import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Skeleton, Button, Space, Spin, notification } from 'antd';
import { TagsOutlined } from '@ant-design/icons';
import union from 'lodash/union';

import { handleAPIError, IFetchQuery } from '@services/base';
import {
  getPenalties,
  IPenalty,
  IPenaltyResult,
  updatePenalties,
} from '@services/penalty';
import useModal, { IModalPayload } from '@hooks/use-modal';

import DataGrid from '../PenaltyApplicationModal/components/DataGrid';
import Form, { IValues } from './components/Form';

interface IProps {
  ids: string[];
  onClose: () => void;
}

const Widget = (props: IProps): JSX.Element | null => {
  const { ids, onClose } = props;
  const [, openResultModal] =
    useModal<IModalPayload<IPenaltyResult[]>>('penalty-result');
  const [data, setData] = useState<IPenalty[]>([]);
  const [values, setValues] = useState<IValues>({});
  const queryClient = useQueryClient();

  const { isFetching } = useQuery(
    ['update-penalty', ids.join('-')],
    () => {
      if (ids?.length) {
        return getPenalties({ ids: ids, page: 1, page_size: ids?.length });
      }
    },
    {
      keepPreviousData: false,
      onSuccess: data => {
        setData(data?.penalties || []);
      },
      onError: handleAPIError,
    },
  );
  const { isLoading: isSubmitting, mutate } = useMutation(
    (payload: IFetchQuery[]) => {
      return updatePenalties(payload);
    },
    {
      onSuccess: async res => {
        onClose();
        await queryClient.invalidateQueries('penalties');
        if (res.every(({ success }) => success)) {
          notification.success({
            message: 'Assign tag',
            description: 'Assigned tag successfully',
          });
        } else {
          openResultModal({ isOpen: true, payload: res || [] });
        }
      },
      onError: handleAPIError,
    },
  );

  const submit = () => {
    const payload: IFetchQuery[] = data
      .filter(({ message }) => !message)
      .map(item => {
        const { _id, tags } = item;
        return {
          _id,
          tags:
            data.length > 1
              ? union([...(tags || []), ...(values.tags || [])])
              : union(values.tags || []),
        };
      });
    mutate(payload);
  };

  const onChange = (data: IValues) => {
    setValues({
      ...values,
      ...data,
    });
  };

  const onRemove = (id: string) => {
    const filtered = data.filter(({ _id }) => id !== _id);
    if (filtered.length) {
      setData(filtered);
    } else {
      onClose();
    }
  };

  if (isFetching) {
    return (
      <>
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  if (data) {
    const isValid = true;

    return (
      <Spin spinning={isSubmitting}>
        <DataGrid data={data} onRemove={onRemove} />
        <br />
        <Form
          onChange={onChange}
          initialValues={
            data?.length === 1
              ? {
                  tags: data[0].tags || [],
                }
              : {}
          }
        />
        <div className={'text-center'}>
          <Space align={'center'}>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              onClick={submit}
              disabled={!isValid}
              type="primary"
              icon={<TagsOutlined />}
            >
              Confirm
              {data.length > 1 ? (
                <span className="ml-1">({data.length})</span>
              ) : null}
            </Button>
          </Space>
        </div>
      </Spin>
    );
  }

  return null;
};

export default Widget;
