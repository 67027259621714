import React from 'react';
import { Space, Table, Tag, Typography } from 'antd';
import { IPenaltyResult } from '@services/penalty';

const { Text } = Typography;

interface IProps {
  data: IPenaltyResult[];
}

const Widget = (props: IProps): JSX.Element | null => {
  const { data } = props;

  const columns = [
    {
      title: '',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Status',
      key: 'status',
      render: ({ success }: IPenaltyResult) => {
        if (success) {
          return <Tag color={'success'}>Success</Tag>;
        }
        return <Tag color={'error'}>Failure</Tag>;
      },
    },
    {
      title: 'Message',
      key: 'message',
      dataIndex: 'message',
    },
    {
      title: 'Detail',
      key: 'details',
      render: ({ details }: IPenaltyResult) => {
        if (details?.length) {
          return (
            <Space direction={'vertical'}>
              {details.map(({ message, column }) => (
                <Text type="danger" key={column}>
                  {message}
                </Text>
              ))}
            </Space>
          );
        }
        return null;
      },
    },
  ];

  return (
    <Table
      size={'small'}
      bordered
      columns={columns}
      rowKey="id"
      dataSource={data}
      pagination={false}
      scroll={{ y: 300 }}
    />
  );
};

export default Widget;
