import React from 'react';
import { Drawer } from 'antd';
import { CommentOutlined } from '@ant-design/icons';

import useModal, { IModalPayload } from '@hooks/use-modal';

import Widget from './Widget';
import useAuth from '@hooks/use-auth';

const PenaltyCommentModal = () => {
  let { data } = useAuth();
  const [modal, setModal] =
    useModal<IModalPayload<{ ids: string[]; type: string }>>('update-penalty');

  const ids = modal?.payload?.ids;

  return (
    <Drawer
      destroyOnClose
      width={640}
      title={
        <>
          <CommentOutlined className={'mr-2'} />
          Comment {ids?.length ? <strong>#{ids[0]}</strong> : null}
        </>
      }
      footer={false}
      bodyStyle={{ padding: 0, overflow: 'hidden' }}
      placement="right"
      onClose={() => {
        setModal({ ...modal, isOpen: false });
      }}
      visible={(modal?.isOpen && modal?.payload?.type === 'comment') || false}
    >
      <Widget
        userEmail={data?.email}
        threadId={`penalties-${ids ? ids[0] : null}`}
        ids={ids || []}
      />
    </Drawer>
  );
};

export default PenaltyCommentModal;
