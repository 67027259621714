import React from 'react';
import { Tag, Tooltip } from 'antd';

import { IPenalty } from '@services/penalty';
import useModal, { IModalPayload } from '@hooks/use-modal';

const CellTag = ({ data }: { data: IPenalty }): JSX.Element => {
  const { tags, _id } = data;
  const [, updatePenalty] =
    useModal<IModalPayload<{ ids: string[]; type: string }>>('update-penalty');

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    updatePenalty({
      isOpen: true,
      payload: {
        type: 'assign-tag',
        ids: [_id],
      },
    });
  };

  return (
    <>
      {(tags?.map &&
        tags?.map((tag, i) => {
          return (
            <Tag style={{ marginBottom: '8px' }} key={tag + `-${i}`}>
              <a href="/" onClick={onClick}>
                {tag}
              </a>
            </Tag>
          );
        })) ||
        null}
      <Tooltip title="Click to assign the new tag">
        <Tag>
          <a href="/" onClick={onClick}>
            +
          </a>
        </Tag>
      </Tooltip>
    </>
  );
};

export default CellTag;
