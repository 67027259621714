import React, { useState } from 'react';
import { Button, notification, Space, Spin } from 'antd';
import { ImportOutlined, DownloadOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from 'react-query';
import isDate from 'date-fns/isDate';
import parse from 'date-fns/parse';
import { unflatten } from 'flat';

import {
  PenaltyNoShowImportSchema,
  INoShowPenaltyImport,
  IPenaltyError,
  IPenaltyResult,
  getNoShowImportFields,
  importNoShow,
} from '@services/penalty';
import { handleAPIError, IFetchQuery } from '@services/base';
import useModal, { IModalPayload } from '@hooks/use-modal';

import Uploader from '../components/no-show/Uploader';
import Preview from '../components/no-show/Preview';

const NoShowImportContext = (props: { onClose: () => void }): JSX.Element => {
  const queryClient = useQueryClient();

  const [, openResultModal] =
    useModal<IModalPayload<IPenaltyResult[]>>('penalty-result');
  const [data, setData] = useState<INoShowPenaltyImport[]>([]);
  const handleClose = () => {
    setData([]);
    props.onClose();
  };

  const downloadTemplate = () => {
    const headers = getNoShowImportFields();
    let csvContent = headers.join(',') + '\r\n';
    const emptyLine = Array(headers.length).fill('').join(',') + '\r\n';
    for (let i = 0; i < 100; i++) {
      csvContent += emptyLine;
    }
    const blob = new Blob([csvContent], {
      type: 'text/csv;charset=utf-8;',
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'no-show-import-template.csv';
    link.click();
    URL.revokeObjectURL(url);
  };

  const onParsed = (data: INoShowPenaltyImport[]) => {
    const newData = data.map(item => {
      Object.keys(item).forEach(key => {
        const value = item[key as keyof INoShowPenaltyImport];
        if (!value) {
          delete item[key as keyof INoShowPenaltyImport];
        }
      });

      const errors: IPenaltyError[] = [];
      const { error } = PenaltyNoShowImportSchema.validate(item, {
        abortEarly: false,
      });
      if (error?.details?.length) {
        error.details.forEach(item => {
          errors.push({
            message: item.message,
            column: item.context?.key || '',
          });
        });
      }

      return {
        ...item,
        errors,
      } as INoShowPenaltyImport;
    });

    setData(newData);
  };

  const submit = () => {
    try {
      mutate(
        data.map(item => {
          let newData: any = {
            ...item,
          };

          Object.keys(newData).forEach(key => {
            const value: any = item[key as keyof INoShowPenaltyImport];
            const date = parse(value, 'MM/dd/yyyy HH:mm:ss', new Date());
            if (
              date &&
              (/_at$/.test(key) ||
                /_date$/.test(key) ||
                /_on$/.test(key) ||
                /timestamp$/.test(key)) &&
              isDate(date)
            ) {
              newData[key as keyof INoShowPenaltyImport] =
                date.toISOString() as never;
            } else if (
              !isNaN(value) &&
              value !== '' &&
              !isDate(value) &&
              typeof value !== 'object'
            ) {
              // Check if the value is numeric and not a date
              newData[key as keyof INoShowPenaltyImport] = Number(value);
            }
          });

          newData = unflatten(newData, { object: true });
          delete newData['errors'];
          return newData;
        }),
      );
    } catch (error: any) {
      console.error(error);
      notification.error({
        message:
          'There was an error parsing your CSV, please check the inserted data',
      });

      notification.error({ message: error.message });
    }
  };

  const { isLoading: isSubmitting, mutate } = useMutation(
    (payload: IFetchQuery[]) => {
      return importNoShow(payload);
    },
    {
      onSuccess: async (res: any) => {
        await queryClient.invalidateQueries('penalties');
        handleClose();
        // @ts-ignore
        if (res?.every(({ success }) => success)) {
          notification.success({
            message: 'Import penalty',
            description: 'Imported successfully',
          });
        } else {
          openResultModal({ isOpen: true, payload: res || [] });
        }
      },
      onError: handleAPIError,
    },
  );

  const isValid = !data.some(({ errors }) => errors?.length);

  return (
    <div>
      {data?.length ? (
        <Spin spinning={isSubmitting}>
          <div style={{ maxHeight: '280px', overflow: 'hidden' }}>
            <Preview data={data} />
          </div>
          <br />
          <div className={'text-center'}>
            <Space align={'center'}>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                onClick={submit}
                disabled={data.length === 0 || !isValid}
                type="primary"
                icon={<ImportOutlined />}
              >
                Import
              </Button>
            </Space>
          </div>
        </Spin>
      ) : (
        <>
          <Uploader onParsed={onParsed} />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '10px',
            }}
          >
            <Button
              type="primary"
              onClick={downloadTemplate}
              icon={<DownloadOutlined />}
            >
              Download template
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default NoShowImportContext;
