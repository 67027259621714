import React from 'react';
import useModal, { IModalPayload } from '@hooks/use-modal';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import Widget from './Widget';

const PenaltyInvoiceModal = () => {
  const [modal, setModal] =
    useModal<IModalPayload<{ ids: string[]; type: string; time: number }>>(
      'update-penalty',
    );

  const { ids = [] } = modal?.payload || {};

  const onClose = () => {
    setModal({ isOpen: false, payload: null });
  };

  return (
    <Modal
      destroyOnClose
      centered
      width={640}
      onCancel={onClose}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      footer={null}
      title={
        <>
          <CloseOutlined className={'mr-2'} />
          Remove penalty
        </>
      }
      visible={(modal?.isOpen && modal?.payload?.type === 'remove') || false}
    >
      <Widget
        ids={ids}
        onClose={onClose}
        processingTime={modal?.payload?.time as number}
      />
    </Modal>
  );
};

export default PenaltyInvoiceModal;
