import { Layout } from 'antd';

import PenaltyDetailModal from '@modules/PenaltyDetailModal';
import PenaltyCarrierDetailModal from '@modules/PenaltyCarrierModal';
import PenaltyApplicationModal from '@modules/PenaltyApplicationModal';
import PenaltyRemovalModal from '@modules/PenaltyRemovalModal';
import PenaltyEditModal from '@modules/PenaltyEditModal';
import PenaltyInvoiceModal from '@modules/PenaltyInvoiceModal';
import PenaltyArchiveModal from '@modules/PenaltyArchiveModal';
import PenaltyDeleteModal from '@modules/PenaltyDeleteModal';
import PenaltyRequestInformationModal from '@modules/PenaltyRequestInformationModal';
import PenaltyAssignTagModal from '@modules/PenaltyAssignTagModal';
import PenaltyResultModal from '@modules/PenaltyResultModal';
import PenaltyCommentModal from '@modules/PenaltyCommentModal';
import PenaltySubmissionModal from '@modules/PenaltySubmissionModal';
import ImportCSVModal from '@modules/ImportCSVModal';
import AddUserModal from '@modules/AddUserModal';

import Widget from './Widget';
import PenaltyResetModal from '@modules/PenaltyResetModal';
import ImportNoShowModal from '@modules/ImportNoShowModal';
import PenaltyUndoBillingModal from '@modules/PenaltyUndoBillingModal';
import ImportNoShowCSVModal from '@modules/ImportNoShowCSVModal';
import UploadInternalFilesModal from './UploadInternalFilesModal';

const HomePage = () => {
  return (
    <Layout style={{ height: '100%' }}>
      <Widget />
      <PenaltyDetailModal />
      <PenaltyCarrierDetailModal />
      <PenaltyApplicationModal />
      <PenaltyRemovalModal />
      <PenaltyEditModal />
      <PenaltyInvoiceModal />
      <PenaltyArchiveModal />
      <PenaltyDeleteModal />
      <PenaltyRequestInformationModal />
      <PenaltyCommentModal />
      <PenaltyAssignTagModal />
      <PenaltyResetModal />
      <PenaltyResultModal />
      <PenaltySubmissionModal />
      <ImportCSVModal />
      <ImportNoShowCSVModal />
      <ImportNoShowModal />
      <AddUserModal />
      <PenaltyUndoBillingModal />
      <UploadInternalFilesModal />
    </Layout>
  );
};

export default HomePage;
