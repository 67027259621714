import React from 'react';
import { Descriptions, Tag, Typography } from 'antd';
import { FormattedDate } from 'react-intl';

import {
  IPenalty,
  PenaltyCategory,
  PenaltyJustificationCategory,
} from '@services/penalty';
import { FileOutlined } from '@ant-design/icons';

const { Text, Link } = Typography;

const Penalty = ({ data }: { data: IPenalty }) => {
  return (
    <Descriptions size={'middle'} title="Penalty" bordered column={2}>
      <Descriptions.Item label="Route">
        <Text>{data.route_composition}</Text>
        {data.route_km && data.route_km > 0 ? (
          <>
            <br />
            <Text type={'secondary'}>{data.route_km} Km</Text>
          </>
        ) : null}
      </Descriptions.Item>
      <Descriptions.Item label="Tour">
        <Text>{data.tour_composition}</Text>
        {data.tour_composition ? (
          <>
            <br />
            <Text type={'secondary'}>{data.tour_km} Km</Text>
          </>
        ) : null}
      </Descriptions.Item>
      <Descriptions.Item label="Initial Delay">
        {data.initial_delay_minutes || '-'} min
      </Descriptions.Item>
      <Descriptions.Item label="Delay">
        {data.delay_minutes} min
      </Descriptions.Item>
      <Descriptions.Item label="Delay type">
        {data.delay_type}
      </Descriptions.Item>
      <Descriptions.Item label="Planned departure">
        <FormattedDate
          value={data.planned_departure_at}
          day="numeric"
          month="numeric"
          year="numeric"
          hour="numeric"
          minute="numeric"
        ></FormattedDate>
      </Descriptions.Item>
      <Descriptions.Item label="Actual departure">
        <FormattedDate
          value={data.actual_departure_at?.timestamp}
          day="numeric"
          month="numeric"
          year="numeric"
          hour="numeric"
          minute="numeric"
        ></FormattedDate>
      </Descriptions.Item>
      <Descriptions.Item label="Planned arrival">
        <FormattedDate
          value={data.planned_arrival_at}
          day="numeric"
          month="numeric"
          year="numeric"
          hour="numeric"
          minute="numeric"
        ></FormattedDate>
      </Descriptions.Item>
      <Descriptions.Item label="Actual arrival">
        <FormattedDate
          value={data.actual_arrival_at?.timestamp}
          day="numeric"
          month="numeric"
          year="numeric"
          hour="numeric"
          minute="numeric"
        ></FormattedDate>
      </Descriptions.Item>
      <Descriptions.Item label="Tags" span={2}>
        {data?.tags?.map(tag => {
          return (
            <Tag style={{ margin: '4px' }} key={tag}>
              {tag}
            </Tag>
          );
        }) || null}
      </Descriptions.Item>
      {data.justification ? (
        <Descriptions.Item label="Justification" span={2}>
          <Text strong>
            {data.justification.category
              ? PenaltyJustificationCategory[
                  data.justification.category as PenaltyCategory
                ]
              : '-'}
          </Text>
          <br />
          <Text type={'secondary'}>{data.justification.body}</Text>
          {data.justification.attachments_url
            ? data.justification.attachments_url.map((attachment, index) => (
                <Link
                  key={index}
                  className={'ml-2'}
                  href={attachment.url.trim()}
                  target="_blank"
                >
                  <FileOutlined />
                </Link>
              ))
            : null}
        </Descriptions.Item>
      ) : null}
      {data.integration_request_body ? (
        <Descriptions.Item label="Integration request body" span={2}>
          {data.integration_request_body}
        </Descriptions.Item>
      ) : null}
      <Descriptions.Item label="Processing" span={2}>
        <Text strong>{data.processing_category}</Text>
        <br />
        <Text type={'secondary'}>{data.processing_body}</Text>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default Penalty;
