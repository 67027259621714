import useStore from './use-store';

const useModal = <T>(key: string): [T, (value: T) => void] => {
  const modalKey = `modal-${key}`;
  return useStore<T>(modalKey);
};

export interface IModalPayload<T> {
  isOpen: boolean;
  payload: T | null;
}

export default useModal;
