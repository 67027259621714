import React from 'react';
import { Typography } from 'antd';
import { FormattedNumber } from 'react-intl';

import { IPenalty } from '@services/penalty';

const { Text } = Typography;

const PenaltyCell = (props: { data: IPenalty }): JSX.Element => {
  const {
    data: { penalty_percentage, total_net_cost, initial_penalty_percentage },
  } = props;

  const isExcluded =
    props.data.status === 'NOT_APPLIED' &&
    props.data.tags?.includes('EXCLUDED');
  const amount = isExcluded
    ? (initial_penalty_percentage || 0) * (total_net_cost || 0)
    : (penalty_percentage || 0) * (total_net_cost || 0);

  if (isExcluded && !penalty_percentage) {
    return (
      <>
        <Text>
          <FormattedNumber value={Number(amount.toFixed(2))} />€ (<b>Initial</b>
          )
        </Text>
        <br />
        {initial_penalty_percentage ? (
          <Text type={'secondary'}>{initial_penalty_percentage * 100}%</Text>
        ) : null}
      </>
    );
  } else
    return (
      <>
        <Text>
          <FormattedNumber value={Number(amount.toFixed(2))} />€
        </Text>
        <br />
        {penalty_percentage ? (
          <Text type={'secondary'}>{penalty_percentage * 100}%</Text>
        ) : null}
      </>
    );
};

export default PenaltyCell;
