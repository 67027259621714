import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Layout from 'antd/lib/layout';

import useAuth from '@hooks/use-auth';

import logo from '@assets/images/logo.svg';

import styles from './styles.module.scss';

const { Content } = Layout;

const AuthLayout = () => {
  let { data, isAuthenticating } = useAuth();

  if (isAuthenticating) {
    return null;
  }

  if (data) {
    return <Navigate to="/" replace />;
  }

  return (
    <Content className={styles.content}>
      <div className={styles.block}>
        <div className="text-center">
          <img src={logo} alt={'Sennder'} />
        </div>
        <Outlet />
      </div>
    </Content>
  );
};

export default AuthLayout;
