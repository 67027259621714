import { apiRequest, IResponse, IFetchQuery } from '@services/base';

import { ICarrier } from './carrier.model';

const apiPrefix = process.env.REACT_APP_API_PATH_PREFIX;

export const getCarriers = async () => {
  return apiRequest.get<IResponse & { carriers: ICarrier[] }>(
    `${apiPrefix}/carriers`,
  );
};

export const getCarrier = async (id: number) => {
  return apiRequest.get<IResponse & { carrier: ICarrier }>(
    `${apiPrefix}/carriers/${id}`,
  );
};

export const updateCarrier = async (id: number, payload: IFetchQuery) => {
  return apiRequest.put<ICarrier>(`${apiPrefix}/carriers/${id}`, payload);
};
