import { Button, Modal, notification } from 'antd';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { getCarrier, ICarrier, updateCarrier } from '@services/carrier';
import { ProfileOutlined } from '@ant-design/icons';
import CarrierForm, { IValues } from './components/Form';
import useModal, { IModalPayload } from '@hooks/use-modal';
import { handleAPIError } from '@services/base';

const PenaltyCarrierDetail = () => {
  const [modal, setModal] =
    useModal<IModalPayload<{ id: string }>>('carrier-details');

  const [values, setValues] = useState<IValues>({});

  const [carrier, setCarrier] = useState<ICarrier>({} as ICarrier);

  const onClose = () => {
    setModal({ isOpen: false, payload: null });
  };

  const onChange = (data: IValues) => {
    setValues({
      ...values,
      ...data,
    });
  };

  const confirmValues = () => {
    if (!values.email || !values.vat || !values.address) {
      return notification.error({
        message: 'Error',
        description: 'Please fill every field',
      });
    }
    updateCarrier(carrier.carrier_id, values)
      .then(() => {
        onClose();
        notification.success({
          message: 'Update',
          description: 'Carrier updated successfully',
        });
      })
      .catch(() => {
        notification.error({
          message: 'Error',
          description: 'Cannot update carrier',
        });
      });
  };

  useQuery(
    ['carrier-details', modal?.payload?.id],
    () => {
      if (modal?.payload?.id) {
        return getCarrier(modal.payload.id as unknown as number);
      }
    },
    {
      keepPreviousData: false,
      onSuccess: res => {
        setCarrier(res?.carrier!);
        setValues({
          email: res?.carrier.email,
          vat: res?.carrier.vat as number,
          address: res?.carrier.address,
          contacts: {
            financial: res?.carrier.contacts?.financial,
            notifications: res?.carrier.contacts?.notifications,
          },
        });
      },
      onError: handleAPIError,
    },
  );

  return (
    <Modal
      destroyOnClose
      centered
      width={640}
      onCancel={onClose}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      footer={null}
      title={
        <>
          <ProfileOutlined className={'mr-2'} />
          Carrier Info
        </>
      }
      visible={modal?.isOpen || false}
    >
      {carrier && (
        <CarrierForm
          editable={values}
          onChange={onChange}
          carrier={carrier as ICarrier}
        />
      )}
      <Button
        onClick={confirmValues}
        type="primary"
        style={{ marginTop: '10px' }}
      >
        Confirm
      </Button>
    </Modal>
  );
};

export default PenaltyCarrierDetail;
