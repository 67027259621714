import { apiRequest, IFetchQuery, IResponse } from '@services/base';
import { IInvoice, IInvoicePerPenalty } from './invoice.model'; // Import the new interface

const apiPrefix = process.env.REACT_APP_API_PATH_PREFIX;
const undoBillingPrefix = process.env.REACT_APP_UNDO_BILLING_PATH_PREFIX;
const undoBillingPerPenaltyPrefix = '/undoBillingSinglePenalty';
const invoiceStatsPrefix = '/getInvoiceStats';
const downloadInvoicesPrefix = '/exportInvoices';
const downloadFinancePrefix = '/exportFinancial';

/* export const getInvoices = async (query: IFetchQuery) => {
  const queryString = new URLSearchParams(query).toString();
  return apiRequest.get<IResponse & { invoices: IInvoice[] }>(
    `${apiPrefix}/invoices?${queryString}`,
  );
}; */

export const getInvoices = async (query: IFetchQuery) => {
  const queryString = new URLSearchParams(query).toString();
  const res = await apiRequest.get<IResponse & { invoices: IInvoice[] }>(
    `${apiPrefix}/invoices?${queryString}`,
  );

  /* res?.invoices?.forEach((invoice: IInvoice) => {
    // Add any additional processing if needed
  }); */

  if (query.ids?.length > 0) {
    return {
      ...res,
      invoices: res.invoices.filter(({ _id }) => query.ids.indexOf(_id) >= 0),
    };
  }

  return res;
};

export const getInvoicesViewPerPenalties = async (query: IFetchQuery) => {
  const queryString = new URLSearchParams(query).toString();
  const res = await apiRequest.get<
    IResponse & { invoices: IInvoicePerPenalty[] }
  >(`${apiPrefix}/invoices?result_type=penalty&${queryString}`);

  if (query.ids?.length > 0) {
    return {
      ...res,
      invoices: res.invoices.filter(({ _id }) => query.ids.indexOf(_id) >= 0),
    };
  }

  return res;
};

export const updateInvoice = async (id: string, payload: Partial<IInvoice>) => {
  return apiRequest.put<IResponse & { invoice: IInvoice }>(
    `${apiPrefix}/invoices/${id}`,
    payload,
  );
};

export const deleteInvoice = async (id: string) => {
  return apiRequest.del<IResponse & { invoice: IInvoice }>(
    `${apiPrefix}/invoices/${id}`,
  );
};

export const undoBilling = async (invoice_number: string[]) => {
  if (!invoice_number) {
    throw new Error('Invoice number is required');
  }
  return apiRequest.get<IResponse & { invoice: IInvoice }>(
    `${undoBillingPrefix}?invoice_number=${encodeURIComponent(
      invoice_number.join(','),
    )}`,
  );
};

export const undoBillingPerPenalty = async (penaltiesIds: string[]) => {
  if (!penaltiesIds) {
    throw new Error('Penalty ids is required');
  }
  return apiRequest.get<IResponse & { invoice: IInvoice }>(
    `${undoBillingPerPenaltyPrefix}?ids=${encodeURIComponent(
      penaltiesIds.join(','),
    )}`,
  );
};

export const getStats = async (query?: IFetchQuery) => {
  const queryString = query ? new URLSearchParams(query).toString() : '';
  return apiRequest.get<IResponse & { rdp: IStat; rda: IStat; overall: IStat }>(
    `${invoiceStatsPrefix}?${queryString}`,
  );
};

export const downloadInvoices = async (
  query?: IFetchQuery,
  type: string = 'documents',
) => {
  const queryString = query ? new URLSearchParams(query).toString() : '';
  return apiRequest.get<Blob>(
    `${downloadInvoicesPrefix}?${queryString}&response_type=blob&export_type=${type}`,
  );
};

export const downloadFinanceInvoices = async (
  query?: IFetchQuery,
  type: string = 'documents',
) => {
  const queryString = query ? new URLSearchParams(query).toString() : '';
  return apiRequest.get<Blob>(
    `${downloadFinancePrefix}?${queryString}&response_type=blob&export_type=${type}`,
  );
};

export const compensateInvoices = async (
  idList: string | string[],
  payload: Partial<IInvoice>,
) => {
  const ids = Array.isArray(idList) ? idList.join(',') : idList;
  return apiRequest.put<IResponse & { invoice: IInvoice }>(
    `${apiPrefix}/invoices/actions/compensate?ids=${ids}`,
    payload,
  );
};

export const accountInvoices = async (
  idList: string | string[],
  payload: Partial<IInvoice>,
) => {
  const ids = Array.isArray(idList) ? idList.join(',') : idList;
  return apiRequest.put<IResponse & { invoice: IInvoice }>(
    `${apiPrefix}/invoices/actions/account?ids=${ids}`,
    payload,
  );
};

interface IStat {
  _id: string | null;
  count: number;
  total_amount: number;
}
