import React from 'react';
import { notification, Typography, Upload } from 'antd';
import { ImportOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import Papa from 'papaparse';

import { INoShowPenaltyImport } from '@services/penalty';

const { Dragger } = Upload;
const { Text } = Typography;

interface IProps {
  onParsed: (data: INoShowPenaltyImport[]) => void;
}

const Uploader = ({ onParsed }: IProps) => {
  const onChange = async (info: UploadChangeParam<any>) => {
    const { file } = info;
    const content = (await file?.text()) || '';
    if (file.type !== 'text/csv') {
      notification.warning({ message: 'Only accept CSV file' });
      return;
    }

    const { data = [] } = Papa.parse<string[]>(content);
    const titleRows: string[] = data[0];
    const results: INoShowPenaltyImport[] = [];
    data.forEach((cells, index) => {
      if (index > 0) {
        const item = {} as INoShowPenaltyImport;
        cells.forEach((value, rowIndex) => {
          const key = titleRows[rowIndex];
          item[key as keyof INoShowPenaltyImport] = value as never;
        });

        const hasData = cells.some(value => !!value);
        if (hasData) {
          results.push(item);
        }
      }
    });

    onParsed(results);
  };

  return (
    <Dragger
      name="file"
      multiple={false}
      accept={'text/csv'}
      onChange={onChange}
      showUploadList={false}
      beforeUpload={() => false}
      maxCount={1}
    >
      <Text type="secondary">
        <ImportOutlined style={{ fontSize: '40px' }} />
      </Text>
      <div className="my-3"></div>
      <Text type="secondary">
        Click or drag file to this area to upload CSV
      </Text>
    </Dragger>
  );
};

export default Uploader;
