import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Skeleton, Button, Space, Spin, Alert, notification } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import { handleAPIError, IFetchQuery } from '@services/base';
import {
  getPenalties,
  IPenalty,
  IPenaltyResult,
  updatePenalties,
} from '@services/penalty';
import useModal, { IModalPayload } from '@hooks/use-modal';

import DataGrid from '../PenaltyApplicationModal/components/DataGrid';
import Form, { IValues } from './components/Form';

interface IProps {
  ids: string[];
  onClose: () => void;
}

const minutesToPercentage = (minutes: number) => {
  const mapping = [
    { min: 0, max: 15, percentage: 0 },
    { min: 16, max: 20, percentage: 0 },
    { min: 21, max: 30, percentage: 5 },
    { min: 31, max: 45, percentage: 15 },
    { min: 46, max: 60, percentage: 25 },
    { min: 61, max: 90, percentage: 35 },
    { min: 91, max: 120, percentage: 50 },
    { min: 121, max: -1, percentage: 100 },
  ];
  const match = mapping.find(
    m => m.min <= minutes && (m.max >= minutes || m.max === -1),
  );
  return match?.percentage || null;
};

const Widget = (props: IProps): JSX.Element | null => {
  const { ids, onClose } = props;
  const [, openResultModal] =
    useModal<IModalPayload<IPenaltyResult[]>>('penalty-result');
  const [data, setData] = useState<IPenalty[]>([]);
  const [values, setValues] = useState<IValues>({});
  const queryClient = useQueryClient();

  const { isFetching } = useQuery(
    ['update-penalty', ids.join('-')],
    () => {
      if (ids?.length) {
        return getPenalties({ ids: ids, page: 1, page_size: ids?.length });
      }
    },
    {
      keepPreviousData: false,
      onSuccess: res => {
        setData(
          (res?.penalties || []).map(item => {
            const { status } = item;
            return {
              ...item,
              message:
                [
                  'APPLIED',
                  'NOT_APPLIED',
                  'PENDING',
                  'BILLABLE',
                  'BILLED',
                  'TO_REVIEW',
                ].indexOf(status || '') < 0
                  ? 'Invalid status'
                  : '',
            };
          }),
        );
      },
      onError: handleAPIError,
    },
  );
  const { isLoading: isSubmitting, mutate } = useMutation(
    (payload: IFetchQuery[]) => {
      return updatePenalties(payload);
    },
    {
      onSuccess: async res => {
        await queryClient.invalidateQueries('penalties');
        onClose();
        if (res.every(({ success }) => success)) {
          notification.success({
            message: 'Edit Penalty',
            description: 'Edited penalty successfully',
          });
        } else {
          openResultModal({ isOpen: true, payload: res || [] });
        }
      },
      onError: handleAPIError,
    },
  );

  const submit = () => {
    const payload: IFetchQuery[] = data
      .filter(({ message }) => !message)
      .map(item => {
        let validPayload: { [k: string]: any } = { _id: item._id };
        if (values.percentage && values.percentage >= 0)
          validPayload.penalty_percentage = values.percentage! / 100;
        if (values.status) validPayload.status = values.status;
        if (values.delay_type) validPayload.delay_type = values.delay_type;
        if (values.delay_minutes)
          validPayload.delay_minutes = values.delay_minutes;
        return validPayload;
      });
    mutate(payload);
  };

  const onChange = (data: IValues) => {
    if (data.delay_minutes) {
      const minutesPercentage = minutesToPercentage(data.delay_minutes);
      if (minutesPercentage) data.percentage = minutesPercentage;
    }
    setValues({
      ...values,
      ...data,
    });
  };

  const onRemove = (id: string) => {
    const filtered = data.filter(({ _id }) => id !== _id);
    if (filtered.length) {
      setData(filtered);
    } else {
      onClose();
    }
  };

  if (isFetching) {
    return (
      <>
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  if (data) {
    const validItems = data.filter(({ message }) => !message);

    const isValid =
      (values.percentage && values.percentage >= 0) ||
      values.status ||
      values.delay_minutes ||
      (values.delay_type && validItems.length)
        ? true
        : false;

    return (
      <Spin spinning={isSubmitting}>
        {validItems.length && validItems.length < data.length ? (
          <>
            <Alert
              message="The invalid items will be ignored after clicking ‘Confirm‘"
              type="warning"
              showIcon
            />
            <br />
          </>
        ) : null}
        {validItems.length === 0 ? (
          <>
            <Alert message="No valid items" type="error" showIcon />
            <br />
          </>
        ) : null}

        <DataGrid data={data} onRemove={onRemove} />
        <br />
        {validItems.length ? (
          <Form penalties={data} onChange={onChange} />
        ) : null}
        <div className={'text-center'}>
          <Space align={'center'}>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              onClick={submit}
              disabled={!isValid}
              type="primary"
              icon={<CheckCircleOutlined />}
            >
              Confirm
              {validItems.length > 1 ? (
                <span className="ml-1">({validItems.length})</span>
              ) : null}
            </Button>
          </Space>
        </div>
      </Spin>
    );
  }

  return null;
};

export default Widget;
