import React from 'react';
import { Drawer, Skeleton } from 'antd';
import { useQuery } from 'react-query';
import { getPenalty } from '@services/penalty';
import { InfoCircleOutlined } from '@ant-design/icons';

import useModal, { IModalPayload } from '@hooks/use-modal';

import General from './components/General';
import Penalty from './components/Penalty';
import Financials from './components/Financials';

const PenaltyDetail = () => {
  const [modal, setModal] =
    useModal<IModalPayload<{ id: string }>>('penalty-detail');

  const { isFetching, data: res } = useQuery(
    ['penalty-detail', modal?.payload?.id],
    () => {
      if (modal?.payload?.id) {
        return getPenalty(modal.payload.id);
      }
    },
    {
      keepPreviousData: false,
    },
  );
  const data = res?.penalty;

  return (
    <Drawer
      width={780}
      title={
        <>
          <InfoCircleOutlined className={'mr-2'} />
          Penalty <strong>#{modal?.payload?.id}</strong>
        </>
      }
      placement="right"
      onClose={() => {
        setModal({ ...modal, isOpen: false });
      }}
      visible={modal?.isOpen || false}
    >
      {isFetching ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : data ? (
        <>
          <General data={data} />
          <br />
          <Penalty data={data} />
          <br />
          <Financials data={data} />
        </>
      ) : null}
    </Drawer>
  );
};

export default PenaltyDetail;
