import { LinkOutlined } from '@ant-design/icons';
import { uploadInternalFile } from '@services/penalty';
import { notification, Progress } from 'antd';
import { useEffect, useState } from 'react';

const FileUpload = ({
  data,
  sennderRef,
  competence,
}: {
  data: any;
  sennderRef: string | null;
  competence: string | null;
}) => {
  const [uploaderData, setUploaderData] = useState<any[]>(
    data
      .filter((file: any, index: number, self: any[]) => {
        return index === self.findIndex(f => f.uid === file.uid);
      })
      .map((file: any) => {
        return {
          name: file.name,
          percentage: 0,
          error: false,
          url: null,
          file,
        };
      }),
  );
  const getDocType = (title: string) => {
    title = title.toLowerCase();
    if (title.includes('gps')) return 'GPS';
    else if (title.includes('speed')) return 'SPEED_GRAPH';
    else return 'OTHER';
  };
  useEffect(() => {
    const processFiles = async () => {
      setUploaderData(prevData =>
        prevData.map(doc => ({
          ...doc,
          percentage: doc.percentage === 0 ? 50 : doc.percentage,
        })),
      );
      for (let doc of uploaderData) {
        let ref;
        let comp;
        let fileType;
        const docSplit = doc.name.split('_');
        ref = sennderRef || docSplit[0];
        comp = competence || docSplit[1];
        fileType = getDocType(doc.name);
        const file = doc.file;

        try {
          const res = await uploadInternalFile(
            ref as string,
            comp,
            fileType,
            file,
          );
          setUploaderData(prevData =>
            prevData.map(item =>
              item.name === doc.name
                ? { ...item, percentage: 100, url: res.url }
                : item,
            ),
          );
        } catch (error: any) {
          notification.error({
            message: `${sennderRef}: ${error.response.data.error}`,
          });
          setUploaderData(prevData =>
            prevData.map(item =>
              item.name === doc.name
                ? { ...item, percentage: 70, error: true }
                : item,
            ),
          );
        }
      }
    };
    processFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {data.map((file: any) => {
        const uploadData = uploaderData.find((d: any) => d.name === file.name);
        return (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
            {uploadData.url ? (
              <a href={uploadData.url} target="_blank" rel="noreferrer">
                {file.name} <LinkOutlined />
              </a>
            ) : (
              <p>{file.name}</p>
            )}
            {uploadData.error ? (
              <Progress percent={uploadData.percentage} status="exception" />
            ) : (
              <Progress percent={uploadData.percentage} />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default FileUpload;
