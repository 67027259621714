import React from 'react';
import { Typography } from 'antd';
import { PushpinFilled } from '@ant-design/icons';

import { IPenalty } from '@services/penalty';

const { Title, Text } = Typography;

const ReferenceCell = (props: IPenalty): JSX.Element => {
  const { sennder_reference, gps_tracked, customer_reference } = props;
  const url = `https://octopus.sennder.com/ordering/${sennder_reference}`;
  return (
    <>
      <Title level={5} style={{ marginBottom: '0px' }}>
        <a href={url} target="_blank" rel="noreferrer">
          {gps_tracked ? <PushpinFilled className={'mr-2'} /> : null}
          {sennder_reference}
        </a>
      </Title>
      <Text type={'secondary'} style={{ fontSize: '12px' }}>
        {customer_reference}
      </Text>
    </>
  );
};

export default ReferenceCell;
