import React from 'react';
import useModal, { IModalPayload } from '@hooks/use-modal';
import { Modal } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

import Widget from './Widget';

const PenaltyArchiveModal = () => {
  const [modal, setModal] =
    useModal<IModalPayload<{ ids: string[]; type: string }>>('update-penalty');

  const { ids = [] } = modal?.payload || {};

  const onClose = () => {
    setModal({ isOpen: false, payload: null });
  };

  return (
    <Modal
      destroyOnClose
      centered
      width={640}
      onCancel={onClose}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      footer={null}
      title={
        <>
          <SaveOutlined className={'mr-2'} />
          Archive penalty
        </>
      }
      visible={(modal?.isOpen && modal?.payload?.type === 'archive') || false}
    >
      <Widget ids={ids} onClose={onClose} />
    </Modal>
  );
};

export default PenaltyArchiveModal;
