import { Button, Space, Tooltip, Typography, notification } from 'antd';
import {
  CheckOutlined,
  CloudDownloadOutlined,
  DownloadOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import classNames from 'classnames';
import useAuth from '@hooks/use-auth';
import useModal, { IModalPayload } from '@hooks/use-modal';
import { downloadInvoices, downloadFinanceInvoices } from '@services/invoice';

const { Text } = Typography;

const BulkActions = ({
  documents,
  isPenaltyView = false,
}: {
  documents: {
    invoice_number: string;
    _id: string;
    status: String;
    row_key: String | null;
  }[];
  isPenaltyView: boolean;
}) => {
  const { data } = useAuth();

  const { flatPermissions = {} } = data || {};
  const type = isPenaltyView ? 'penalty' : 'invoice';

  const [, openCompensationModal] =
    useModal<IModalPayload<{ ids: string[] }>>('invoice-compensate');
  const [, openAccountModal] =
    useModal<IModalPayload<{ ids: string[] }>>('invoice-account');
  const [, openCreateRdaModal] =
    useModal<IModalPayload<{ ids: string[]; type: string }>>('invoice-rda');

  const handleDownloadCSV = () => {
    if (isPenaltyView) {
      const ids = documents.map(doc => doc.row_key);
      downloadInvoices({ penalties_ids: ids }, 'penalty')
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'invoices.csv');
          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
        })
        .then(() => {
          notification.success({
            message: 'Success',
            description: 'Invoices downloaded successfully',
          });
        })
        .catch(() => {
          notification.error({
            message: 'Error',
            description: 'Cannot download Invoices',
          });
        });
    } else {
      const ids = documents.map(doc => doc.invoice_number);
      downloadInvoices({ invoice_number: ids })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'invoices.csv');
          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
        })
        .then(() => {
          notification.success({
            message: 'Success',
            description: 'Invoices downloaded successfully',
          });
        })
        .catch(() => {
          notification.error({
            message: 'Error',
            description: 'Cannot download Invoices',
          });
        });
    }
  };

  const handleDownloadFinanceCSV = () => {
    const ids = documents.map(doc => doc.invoice_number);
    downloadFinanceInvoices({ invoice_number: ids })
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'invoices.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      })
      .then(() => {
        notification.success({
          message: 'Success',
          description: 'Finance invoices downloaded successfully',
        });
      })
      .catch(() => {
        notification.error({
          message: 'Error',
          description: 'Cannot download finance invoices',
        });
      });
  };

  const handleCompensate = () => {
    const ids = documents.map(doc => doc._id);
    openCompensationModal({ isOpen: true, payload: { ids } });
  };

  const handleAccount = () => {
    const ids = documents.map(doc => doc._id);
    openAccountModal({ isOpen: true, payload: { ids } });
  };

  const handleCreateRda = () => {
    openCreateRdaModal({
      isOpen: true,
      payload: {
        ids: documents.map(doc => {
          return isPenaltyView ? doc._id : doc.invoice_number;
        }),
        type: type,
      },
    });
  };

  const allDocumentsAccounted = documents.every(
    doc => doc.status === 'ACCOUNTED',
  );
  const allDocumentsInvoiced = documents.every(
    doc => doc.status === 'INVOICED',
  );

  const actions = [
    {
      colorType: 'default',
      permissions: ['edit'],
      icon: <DownloadOutlined />,
      label: 'Download CSV',
      onClick: handleDownloadCSV,
    },
    {
      colorType: 'default',
      permissions: ['edit'],
      icon: <CloudDownloadOutlined />,
      label: 'Download Finance CSV',
      onClick: handleDownloadFinanceCSV,
    },
    isPenaltyView && {
      colorType: 'default',
      permissions: ['edit'],
      icon: <FileTextOutlined />,
      label: 'Create RDA',
      onClick: handleCreateRda,
    },
    allDocumentsAccounted && {
      colorType: 'default',
      permissions: ['edit'],
      icon: <CheckOutlined />,
      label: 'Compensate',
      onClick: handleCompensate,
    },
    allDocumentsInvoiced && {
      colorType: 'default',
      permissions: ['edit'],
      icon: <CheckOutlined />,
      label: 'Account',
      onClick: handleAccount,
    },
  ].filter(Boolean);

  const getActionItem = (item: any) => {
    const { permissions = [] } = item;
    const hasPermissions = permissions.length
      ? permissions.some((role: any) => flatPermissions[role])
      : true;

    if (hasPermissions) {
      return (
        <Tooltip key={item.label} title={item.label}>
          <Button onClick={item.onClick} type={item.colorType}>
            {item.icon}
          </Button>
        </Tooltip>
      );
    }
    return null;
  };

  const actionsItems = actions.map(item => getActionItem(item));

  return (
    <div
      className={classNames('bulk-actions', {
        'bulk-actions--active': true,
      })}
    >
      <Space>
        <Text>
          <>
            <strong className="mr-1">{documents.length}</strong> selected items
          </>
        </Text>
        {actionsItems}
      </Space>
    </div>
  );
};

export default BulkActions;
