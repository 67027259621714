import React from 'react';
import { Typography, Space, Popover } from 'antd';

import { IPenalty } from '@services/penalty';

const { Text } = Typography;

const CellBilledAmount = ({ data }: { data: IPenalty }): JSX.Element => {
  const {
    billed_amount,
    billed_at,
    billed_undo_invoice_number,
    billed_undo_author,
    invoice_number,
    billed_undo_at,
  } = data;

  const hoverContent = (
    <Space direction={'vertical'}>
      <Text>
        <span className="mr-2">Billed At</span>
        {data.billed_at ? <Text>{billed_at}</Text> : null}
      </Text>
      <Text>
        <span className="mr-2">Invoice Number:</span>
        {data.invoice_number ? <Text>{invoice_number as any}</Text> : null}
      </Text>
      <Text>
        <span className="mr-2">Cancelled Invoice Number:</span>
        {data.billed_undo_invoice_number ? (
          <Text>{billed_undo_invoice_number as any}</Text>
        ) : null}
      </Text>
      <Text>
        <span className="mr-2">Cancelled by:</span>
        {data.billed_undo_author ? (
          <Text>{billed_undo_author as any}</Text>
        ) : null}
      </Text>
      <Text>
        <span className="mr-2">Cancelled at:</span>
        {data.billed_undo_at ? <Text>{billed_undo_at as any}</Text> : null}
      </Text>
    </Space>
  );
  return (
    <>
      <Popover content={hoverContent} destroyTooltipOnHide>
        <Text>
          {billed_amount || billed_amount === 0
            ? `${billed_amount as any}€`
            : '-'}
        </Text>
      </Popover>
    </>
  );
};

export default CellBilledAmount;
