import React, { useMemo, useRef, useState } from 'react';
import { Popover, Table, notification } from 'antd';
import type { ColumnsType, TableProps } from 'antd/lib/table';
import { useQuery } from 'react-query';
import { getInvoicesViewPerPenalties, getStats } from '@services/invoice';
import { IInvoicePerPenalty } from '@services/invoice/invoice.model';
import ActionCell from './ActionCell';
import BulkActions from './BulkActions';

interface PenaltiesTableProps {
  query: any;
  setQuery: (query: any) => void;
  pagination: any;
  setPagination: (pagination: any) => void;
  selectedRows: React.Key[];
  setSelectedRows: (rows: React.Key[]) => void;
  processingIds: string[];
  setProcessingIds: (ids: string[]) => void;
  filter: any;
  setStats: (stats: any) => void;
}

const PenaltiesTable: React.FC<PenaltiesTableProps> = ({
  query,
  setQuery,
  pagination,
  setPagination,
  selectedRows,
  setSelectedRows,
  processingIds,
  setProcessingIds,
  filter,
  setStats,
}) => {
  const refContainer = useRef<HTMLDivElement>(null);
  const [data, setData] = useState<IInvoicePerPenalty[]>([]);

  const mappedDocuments = useMemo(
    () =>
      data
        .filter(invoice => selectedRows.includes(invoice.row_key))
        .map(invoice => ({
          invoice_number: invoice.invoice_number,
          _id: invoice._id,
          status: invoice.status,
          row_key: invoice.row_key,
        })),
    [selectedRows, data],
  );

  // eslint-disable-next-line
  const [isLoading, setLoading] = useState<boolean>(false);
  const columns: ColumnsType<IInvoicePerPenalty> = [
    {
      title: 'Sennder Reference',
      dataIndex: 'sennder_reference',
      key: 'sennder_reference',
      width: 150,
    },
    {
      title: 'Document Number',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      sorter: (a, b) => Number(a.invoice_number) - Number(b.invoice_number),
      width: 150,
      render: (text: string, record: IInvoicePerPenalty) => (
        <a href={record.invoice_doc} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
    },
    {
      title: 'Carrier',
      dataIndex: 'carrier_name',
      key: 'carrier_name',
      width: 150,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a, b) => a.amount - b.amount,
      width: 150,
    },
    {
      title: 'Competence',
      dataIndex: 'competence',
      key: 'competence',
      render: text =>
        `${new Date(text)
          .toLocaleString('default', { month: 'short', year: 'numeric' })
          .toUpperCase()}`,
      width: 150,
    },
    {
      title: 'Emission',
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => new Date(text).toLocaleDateString('it-IT'),
      width: 150,
    },
    {
      title: 'Status',
      key: 'status',
      render: (data: IInvoicePerPenalty) => {
        return (
          <Popover
            content={
              <div>
                <p>
                  Accounted on:{' '}
                  {data.accounted_at ? (
                    new Date(data.accounted_at)
                      .toLocaleString('it-IT', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })
                      .replace(',', '')
                  ) : (
                    <b>No date found</b>
                  )}
                </p>
                <p>
                  Compensated on:{' '}
                  {data.compensated_at ? (
                    new Date(data.compensated_at)
                      .toLocaleString('it-IT', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })
                      .replace(',', '')
                  ) : (
                    <b>No date found</b>
                  )}
                </p>
              </div>
            }
            title="Compensation and Accounting"
          >
            {data.status}
          </Popover>
        );
      },
      width: 100,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 100,
    },
    {
      key: 'actions',
      width: 84,
      fixed: 'right',
      render: (data: IInvoicePerPenalty) => {
        return (
          <ActionCell
            data={data}
            isPenaltyView={true}
            processing={processingIds.includes(data._id)}
          />
        );
      },
    },
  ];

  const { isFetching } = useQuery(
    ['invoices', filter, query],
    () => getInvoicesViewPerPenalties({ ...query, ...filter }),
    {
      onSuccess: async (res: { data: IInvoicePerPenalty[]; total: number }) => {
        setData(res.data);
        const stats = await getStats(filter);
        setStats(stats);
        setPagination({ ...pagination, total: res.total });
      },
      onError: (error: { message: string }) => {
        notification.error({
          message: 'Error fetching invoices',
          description: error.message,
        });
      },
    },
  );

  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRows(selectedRowKeys);
  };

  const onChangeTable: TableProps<IInvoicePerPenalty>['onChange'] =
    pagination => {
      setQuery({ ...query, page: pagination.current ?? 1 });
    };
  return (
    <div ref={refContainer} style={{ height: '80%' }}>
      <Table
        rowSelection={{
          selectedRowKeys: selectedRows,
          onChange: onSelectChange,
        }}
        loading={isFetching || isLoading}
        onChange={onChangeTable}
        columns={columns}
        pagination={pagination}
        rowKey="row_key" // Ensure the rowKey is set to a unique identifier
        dataSource={data}
        scroll={
          refContainer?.current
            ? {
                y: refContainer.current.clientHeight - 56 - 56,
                x: refContainer.current.clientWidth,
              }
            : undefined
        }
      />
      {selectedRows.length > 0 && (
        <BulkActions documents={mappedDocuments} isPenaltyView={true} />
      )}
    </div>
  );
};

export default PenaltiesTable;
