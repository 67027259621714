import { Typography } from 'antd';

import {
  IPenalty,
  PenaltyCategory,
  PenaltyJustificationCategory,
} from '@services/penalty';

const { Text } = Typography;

const CellJustification = ({
  data,
}: {
  data: IPenalty;
}): JSX.Element | null => {
  const { justification } = data;

  if (justification) {
    const justificationCategory = justification.category
      ? PenaltyJustificationCategory[justification.category as PenaltyCategory]
      : '-';

    return (
      <>
        <Text strong>{justificationCategory}</Text>
        <br />
        <Text type={'secondary'}>{justification.body}</Text>
      </>
    );
  }
  return null;
};

export default CellJustification;
