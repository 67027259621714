import { Table } from 'antd';

const DelayMinutesTable = () => {
  const columns = [
    {
      title: 'From',
      dataIndex: 'from',
      key: 'from',
      width: 20,
    },
    {
      title: 'To',
      dataIndex: 'to',
      key: 'to',
      width: 20,
    },
    {
      title: '%',
      dataIndex: 'percentage',
      key: 'percentage',
      width: 20,
    },
  ];

  const data = [
    {
      from: '0 minutes',
      to: '15 minutes',
      percentage: 0,
      key: '0',
    },
    {
      from: '16 minutes',
      to: '20 minutes',
      percentage: 0,
      key: '1',
    },
    {
      from: '21 minutes',
      to: '30 minutes',
      percentage: 5,
      key: '2',
    },
    {
      from: '31 minutes',
      to: '45 minutes',
      percentage: 15,
      key: '3',
    },
    {
      from: '46 minutes',
      to: '60 minutes',
      percentage: 25,
      key: '4',
    },
    {
      from: '61 minutes',
      to: '90 minutes',
      percentage: 35,
      key: '5',
    },
    {
      from: '91 minutes',
      to: '120 minutes',
      percentage: 50,
      key: '6',
    },
    {
      from: '121 minutes',
      to: '-',
      percentage: 100,
      key: '7',
    },
  ];

  return (
    <Table
      dataSource={data}
      columns={columns}
      style={{ maxWidth: '400px' }}
      pagination={{ pageSize: 4 }}
      tableLayout="fixed"
    />
  );
};

export default DelayMinutesTable;
