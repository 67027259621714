import React from 'react';
import { FormattedDate } from 'react-intl';
import { Popover, Space, Typography } from 'antd';

import { IPenalty } from '@services/penalty';

const { Text } = Typography;

const CellDelay = ({ data }: { data: IPenalty }): JSX.Element => {
  const { delay_minutes, delay_type, initial_delay_minutes } = data;
  const isExcluded =
    data.status === 'NOT_APPLIED' && data.tags?.includes('EXCLUDED');

  const hoverContent = (
    <Space direction={'vertical'}>
      <Text>
        <span className="mr-2">Initial Delay:</span>
        {data.initial_delay_minutes ? (
          <>{data.initial_delay_minutes} min</>
        ) : (
          '-'
        )}
      </Text>
      <Text>
        <span className="mr-2">Initial Percentage:</span>
        {data.initial_penalty_percentage ? (
          <>{data.initial_penalty_percentage * 100} %</>
        ) : (
          '-'
        )}
      </Text>

      <Text>
        <span className="mr-2">Planned departure:</span>
        {data.planned_departure_at ? (
          <FormattedDate
            value={data.planned_departure_at}
            day="numeric"
            month="numeric"
            year="numeric"
            hour="numeric"
            minute="numeric"
          ></FormattedDate>
        ) : null}
      </Text>
      <Text>
        <span className="mr-2">Actual departure:</span>
        {data.actual_departure_at ? (
          <FormattedDate
            value={data.actual_departure_at.timestamp}
            day="numeric"
            month="numeric"
            year="numeric"
            hour="numeric"
            minute="numeric"
          ></FormattedDate>
        ) : null}
      </Text>
      <Text>
        <span className="mr-2">Planned arrival:</span>
        {data.planned_arrival_at ? (
          <FormattedDate
            value={data.planned_arrival_at}
            day="numeric"
            month="numeric"
            year="numeric"
            hour="numeric"
            minute="numeric"
          ></FormattedDate>
        ) : null}
      </Text>
      <Text>
        <span className="mr-2">Actual arrival:</span>
        {data.actual_arrival_at ? (
          <FormattedDate
            value={data.actual_arrival_at.timestamp}
            day="numeric"
            month="numeric"
            year="numeric"
            hour="numeric"
            minute="numeric"
          ></FormattedDate>
        ) : null}
      </Text>
    </Space>
  );
  if (isExcluded && !delay_minutes) {
    return (
      <Popover content={hoverContent} destroyTooltipOnHide>
        {initial_delay_minutes ? (
          <Text>
            {initial_delay_minutes} min (<b>Initial</b>)
          </Text>
        ) : null}
        <br />
        <Text type={'secondary'}>{delay_type}</Text>
      </Popover>
    );
  } else
    return (
      <Popover content={hoverContent} destroyTooltipOnHide>
        {delay_minutes ? <Text>{delay_minutes} min</Text> : null}
        <br />
        <Text type={'secondary'}>{delay_type}</Text>
      </Popover>
    );
};

export default CellDelay;
