import React from 'react';
import { Form, Input, Popover, Select } from 'antd';
import { IPenalty } from '@services/penalty';
import DelayMinutesTable from './DelayMinutesTable';

export interface IValues {
  delay_type?: any;
  status?: any;
  percentage?: number;
  category?: string;
  reason?: string;
  delay_minutes?: number;
}

interface IProps {
  penalties: IPenalty[];
  onChange: (values: IValues) => void;
}

const STATUSES = [
  'APPLIED',
  'NOT_APPLIED',
  'PENDING',
  'BILLABLE',
  'BILLED',
  'TO_REVIEW',
];

const PERCENTAGE_STATUS = ['APPLIED', 'NOT_APPLIED', 'PENDING', 'TO_REVIEW'];

const DELAY_TYPES = ['Transit', 'Positioning', 'No Show', 'Departure'];

const EditForm = (props: IProps) => {
  const penalties = props.penalties;
  const { onChange } = props;

  return (
    <Form
      name="penalty"
      initialValues={{}}
      layout="vertical"
      autoComplete="off"
    >
      <Form.Item
        label="Status"
        name="status"
        rules={[{ required: false, message: 'Please select the status' }]}
      >
        <Select
          onChange={value => {
            onChange({ status: value });
          }}
        >
          {STATUSES.map(status => {
            return (
              <Select.Option key={status} value={status}>
                {status}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        label="Delay Type"
        name="delay type"
        rules={[{ required: false, message: 'Please select the delay type' }]}
      >
        <Select
          onChange={value => {
            onChange({ delay_type: value });
          }}
        >
          {DELAY_TYPES.map(delay => {
            return (
              <Select.Option key={delay} value={delay}>
                {delay}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Delay minutes"
        name="delay_minutes"
        rules={[{ required: false, message: 'Please enter the delay minutes' }]}
      >
        <Input
          onChange={event => {
            onChange({ delay_minutes: parseInt(event.target?.value) || -1 });
          }}
        />
        <small style={{ cursor: 'pointer' }}>
          The minutes edit will automatically change the percentage based on{' '}
          <Popover
            content={DelayMinutesTable}
            title="Delay minutes to percentage conversion"
          >
            <b>these parameters</b>
          </Popover>
        </small>
      </Form.Item>

      {penalties.filter(penalty => PERCENTAGE_STATUS.includes(penalty.status!))
        .length === penalties.length && (
        <Form.Item
          label="Percentage"
          name="percentage"
          rules={[{ required: false, message: 'Please enter the percentage' }]}
        >
          <Input
            onChange={event => {
              onChange({ percentage: parseFloat(event.target?.value) || -1 });
            }}
          />
        </Form.Item>
      )}
    </Form>
  );
};

export default EditForm;
