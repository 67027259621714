import React from 'react';
import { Button, Dropdown, Menu, notification } from 'antd';
import {
  CheckOutlined,
  DeleteOutlined,
  FileTextOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import useModal, { IModalPayload } from '@hooks/use-modal';

const ActionCell = ({
  data,
  processing,
  isPenaltyView,
}: {
  data: any;
  processing: boolean;
  isPenaltyView: boolean;
}) => {
  const actions = [];
  const [, openStatusModal] =
    useModal<IModalPayload<{ ids: string[] }>>('invoice-account');

  const [, openDeleteModal] =
    useModal<IModalPayload<{ id: string }>>('invoice-delete');

  const [, openCompensationModal] =
    useModal<IModalPayload<{ ids: string[] }>>('invoice-compensate');

  const [, openCreateRdaModal] =
    useModal<IModalPayload<{ ids: string[]; type: string }>>('invoice-rda');

  if (data.type === 'RDP') {
    actions.push({
      label: 'Create RDA',
      icon: <FileTextOutlined />,
      onClick: async () => {
        try {
          const type = isPenaltyView ? 'penalty' : 'invoice';
          openCreateRdaModal({
            isOpen: true,
            payload: {
              ids: isPenaltyView ? [data._id] : [data.invoice_number],
              type: type,
            },
          });
        } catch (error) {
          notification.error({
            message: 'Failed to create RDA',
            description: error as string,
          });
        }
      },
    });
  }

  if (data.status === 'ACCOUNTED' && !isPenaltyView) {
    actions.push({
      label: 'Compensate',
      icon: <CheckOutlined />,
      onClick: async () => {
        openCompensationModal({ isOpen: true, payload: { ids: [data._id] } });
      },
    });
  }

  if (data.status === 'INVOICED' && !isPenaltyView) {
    actions.push({
      label: 'Delete',
      icon: <DeleteOutlined />,
      onClick: async () => {
        openDeleteModal({ isOpen: true, payload: { id: data._id } });
      },
    });
    if (!isPenaltyView) {
      actions.push({
        label: 'Account',
        icon: <CheckOutlined />,
        onClick: async () => {
          openStatusModal({ isOpen: true, payload: { ids: [data._id] } });
        },
      });
    }
  }
  if (actions.length === 0) {
    return null;
  }

  return (
    <Dropdown
      overlay={
        <Menu>
          {actions.map(action => (
            <Menu.Item
              key={action.label}
              onClick={action.onClick}
              disabled={processing}
            >
              {action.icon || null} {action.label}
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      <Button type="text" icon={<MoreOutlined />} />
    </Dropdown>
  );
};

export default ActionCell;
