import React from 'react';
import { Typography, Space, Popover } from 'antd';
import { FormattedDate } from 'react-intl';

import { IPenalty } from '@services/penalty';

const { Text } = Typography;

const CellWeek = ({ data }: { data: IPenalty }): JSX.Element => {
  const hoverContent = (
    <Space direction={'vertical'}>
      <Text>
        <span className="mr-2">Order Date:</span>
        {data.order_date ? (
          <FormattedDate
            value={data.order_date}
            day="numeric"
            month="numeric"
            year="numeric"
            hour="numeric"
            minute="numeric"
          ></FormattedDate>
        ) : null}
      </Text>
      <Text>
        <span className="mr-2">Penalty Date:</span>
        {data.received_on ? (
          <FormattedDate
            value={data.received_on}
            day="numeric"
            month="numeric"
            year="numeric"
            hour="numeric"
            minute="numeric"
          ></FormattedDate>
        ) : null}
      </Text>
      <Text>
        <span className="mr-2">Expiration Date:</span>
        {data.expiration_date ? (
          <FormattedDate
            value={data.expiration_date}
            day="numeric"
            month="numeric"
            year="numeric"
            hour="numeric"
            minute="numeric"
          ></FormattedDate>
        ) : null}
      </Text>
    </Space>
  );
  return (
    <>
      <Popover content={hoverContent} destroyTooltipOnHide>
        <Text>W{data.week}</Text>
      </Popover>
    </>
  );
};

export default CellWeek;
