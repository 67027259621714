import React from 'react';
import { Form, Input, Select } from 'antd';

const { TextArea } = Input;

export interface IValues {
  category?: string;
  reason?: string;
}

interface IProps {
  onChange: (values: IValues) => void;
}

const REASONS = [
  'Incorrect document',
  'Unreadable document',
  'Unjustifiable delay',
  'Justification not acceptable',
  'Justification partially acceptable',
  'Accepted by the carrier',
];

const ReasonForm = (props: IProps) => {
  const { onChange } = props;
  return (
    <Form
      name="penalty"
      initialValues={{}}
      layout="vertical"
      autoComplete="off"
    >
      <Form.Item
        label="Category"
        name="category"
        rules={[{ required: true, message: 'Please select the category' }]}
      >
        <Select
          onChange={value => {
            onChange({ category: value });
          }}
        >
          {REASONS.map(reason => {
            return (
              <Select.Option key={reason} value={reason}>
                {reason}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Reason"
        name="reason"
        rules={[{ required: true, message: 'Please enter the reason' }]}
      >
        <TextArea
          autoSize={{ minRows: 2 }}
          onChange={event => {
            onChange({ reason: event.target?.value || '' });
          }}
        />
      </Form.Item>
    </Form>
  );
};

export default ReasonForm;
