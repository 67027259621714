import React from 'react';
import { LinkOutlined } from '@ant-design/icons';
import { IPenalty } from '@services/penalty';

const CellInternalFiles = ({
  data,
}: {
  data: IPenalty;
}): JSX.Element | null => {
  if (data.internal_files?.length) {
    return (
      <>
        {data.internal_files.map((file: any) => (
          <div>
            <a key={file.id} href={file.url} target="_blank" rel="noreferrer">
              {file.file_name}
              <LinkOutlined />
            </a>
            <br />
          </div>
        ))}
      </>
    );
  }

  return null;
};

export default CellInternalFiles;
