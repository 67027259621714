import React, { useState } from 'react';
import { Button, DatePicker, Modal, notification, Space, Spin } from 'antd';
import { ImportOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from 'react-query';

import { handleAPIError } from '@services/base';
import useModal, { IModalPayload } from '@hooks/use-modal';

import { IInvoice } from '@services/invoice/invoice.model';
import { compensateInvoices } from '@services/invoice';

const CompensateInvoiceModal = () => {
  const [modal, setModal] = useModal<IModalPayload<any>>('invoice-compensate');
  const [data, setData] = useState<IInvoice[]>([]);
  const [date, setDate] = useState<any>(new Date());
  const onClose = () => {
    setModal({ isOpen: false, payload: null });
    setData([]);
  };

  const handleDateChange = (date: any) => {
    setDate(date);
  };

  const queryClient = useQueryClient();

  const { isLoading: isSubmitting, mutate } = useMutation(
    (payload: { ids: string[]; date: Date }) => {
      return compensateInvoices(payload.ids, {
        status: 'COMPENSATED',
        compensated_at: payload.date,
      });
    },
    {
      onSuccess: async res => {
        onClose();
        await queryClient.invalidateQueries('invoices');
        if (!res.error) {
          notification.success({
            message: 'Success',
            description: 'Invoice compensated successfully',
          });
        } else {
          notification.error({
            message: 'Error',
            description: 'Cannot compensate invoice',
          });
        }
      },
      onError: handleAPIError,
    },
  );

  const submit = () => {
    const payload = {
      ids: modal?.payload.ids,
      date,
    };
    mutate(payload);
  };

  return (
    <Modal
      destroyOnClose
      centered
      width={720}
      onCancel={onClose}
      footer={null}
      title={
        <>
          <ImportOutlined className={'mr-2'} />
          Add compensation date
          {data?.length ? <span className="ml-1">({data.length})</span> : null}
        </>
      }
      visible={modal?.isOpen || false}
    >
      <Spin spinning={isSubmitting}>
        <div
          style={{
            maxHeight: '280px',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <label>Select compensation date</label>
          <DatePicker
            showTime={{
              format: 'HH:mm',
            }}
            onChange={handleDateChange}
            format="YYYY-MM-DD HH:mm"
          />
        </div>
        <br />
        <div className={'text-center'}>
          <Space align={'center'}>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={submit} type="primary" icon={<ImportOutlined />}>
              Confirm
            </Button>
          </Space>
        </div>
      </Spin>
    </Modal>
  );
};

export default CompensateInvoiceModal;
