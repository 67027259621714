import React from 'react';
import { Form, Select } from 'antd';

export interface IValues {
  tags?: string[];
}

interface IProps {
  initialValues: IValues;
  onChange: (values: IValues) => void;
}

const ReasonForm = (props: IProps) => {
  const { onChange, initialValues } = props;
  return (
    <Form
      name="penalty"
      initialValues={initialValues || {}}
      layout="vertical"
      autoComplete="off"
    >
      <Form.Item
        label="Tags"
        name="tags"
        rules={[{ required: true, message: 'Please enter the tags' }]}
      >
        <Select
          showSearch={false}
          mode="tags"
          onChange={(tags: string[]) => {
            onChange({
              tags,
            });
          }}
        ></Select>
      </Form.Item>
    </Form>
  );
};

export default ReasonForm;
